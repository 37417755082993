import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import backendApi from '../../helper.js'
import Inout_List from '../../attendence/ListAPI.js'
import Apply_leave from '../Apply_leave.js'
import { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/custom.css'
import '../../css/style.css'
// import { Link } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Container,
  Form,
  Modal,
  ModalBody,
  Tab,
  Tabs,
} from 'react-bootstrap'
import { Fade, Grid, Input, TextareaAutosize, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Loader from '../../js/loader.js'
import Toastinette from '../../js/toastinette.js'
import { AUTOCLOSE, POSITION, PROGRESS } from '../../constant.js'

const Requests = () => {
  const [leaveData, setLeaveData] = useState([])
  const [rejectLeave, setRejectLeave] = useState([])
  const [historyData, setHistoryData] = useState([])
  const [key, setKey] = useState('Pending')
  const [rejectReason, setRejectReason] = useState([])
  const [selectedLeaveId, setSelectedLeaveId] = useState(null)

  const [selectedLeaveSerial, setSelectedLeaveSerial] = useState(null)

  // <Link to="/apply-leave"></Link>
  const location = useLocation()
  const { leave_type, reason, from_date, to_date, day } = location.search

  useEffect(() => {
    fetchLeaveData()
  }, [])

  const fetchLeaveData = async () => {
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id) {
      const data = {
        action: 'view_admin_time_correction',
        id: hrms_user_id.id,
      }
      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        if (!result.error) {
          setLeaveData(result.pending || [])
          setHistoryData(result.history || [])
        } else {
          console.log('No leaves found')
        }
      } catch (error) {
        console.log('Error fetching leave data:', error)
      }
    }
  }

  const handleApprovedTime = async (leaveId, type, serialnumber) => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    const data = {
      action: 'time_correction_by_staff_id',
      id: leaveId,
      employee_id: serialnumber,
      status: type === 'Approved' ? '1' : '2',
      reject_reason: type === 'Rejected' ? rejectReason : '',
    }
    try {
      const result = await backendApi(data, hrms_user_id.user_auth)
      if (!result.error) {
        setLeaveData(
          leaveData.map((leave) =>
            leave.id === leaveId
              ? { ...leave, status: data.status, reason: data.reject_reason }
              : leave
          )
        )
        if (type === 'Rejected') handleClose()
        fetchLeaveData()
        Toastinette.init({
          position: POSITION,
          title: 'Congratulations',
          message: result.message,
          type: result.type,
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        Loader.close()
      } else {
        console.log(
          `Failed to ${type === 'Approved' ? 'approve' : 'reject'} request:`,
          result.message
        )
        Toastinette.init({
          position: POSITION,
          title: 'Oops...!',
          message: result.message,
          type: result.type,
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        Loader.close()
      }
    } catch (error) {
      console.log('Error during API call:', error)
    }
  }

  const [open, setOpen] = useState(false)

  const handleOpen = (leaveId, serialnumber) => {
    setSelectedLeaveId(leaveId)
    setSelectedLeaveSerial(serialnumber)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setRejectReason('')
    setSelectedLeaveId(null)
  }

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' }
    return new Date(dateString).toLocaleDateString('en-GB', options)
  }

  const formatTimeFromAPI = (time) => {
    if (!time) return ''
    const [hours, minutes] = time.split(':')
    const formattedTime = new Date()
    formattedTime.setHours(hours)
    formattedTime.setMinutes(minutes)
    return formattedTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  return (
    <>
      <section class="leaves-page">
        <section class="leaves-list">
          <Container>
            <div className="col-lg-12 row">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-2"
              >
                <Tab eventKey="Pending" title="Pending">
                  {leaveData && leaveData.length > 0 ? (
                    leaveData.map(
                      (leaveItem, index) =>
                        leaveItem.status === '0' && (
                          <div key={index} className="row leave-item">
                            <div className="col-12 row history-row position-relative">
                              <Accordion className="accord accord-2 custom-margin">
                                <Accordion.Header>
                                  <div className="leave-icon">
                                    <div className="leave-processing">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12 6V12L16 14"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="leave-details">
                                    <h4>{leaveItem.username}</h4>
                                    <p className="date pending">
                                      <span>{formatDate(leaveItem.date)}</span>
                                    </p>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body className="custom-body-fullwidth">
                                  <div className="listing-body custom-margin">
                                    <div className="listing-timing d-flex mb-2">
                                      <div className="intime col-6">
                                        <h5 className="lis-title">
                                          Old In-Time
                                        </h5>
                                        <h5 className="lis-content">
                                          {formatTimeFromAPI(
                                            leaveItem.oldtimein
                                          )}
                                        </h5>
                                      </div>
                                      <div className="outtime col-6">
                                        <h5 className="lis-title">
                                          Old Out-Time
                                        </h5>
                                        <h5 className="lis-content">
                                          {formatTimeFromAPI(
                                            leaveItem.oldtimeout
                                          )}
                                        </h5>
                                      </div>
                                    </div>

                                    <div className="listing-timing d-flex mb-2">
                                      <div className="intime col-6">
                                        <h5 className="lis-title">In Time</h5>
                                        <h5 className="lis-content">
                                          {formatTimeFromAPI(leaveItem.timein)}
                                        </h5>
                                      </div>
                                      <div className="outtime2 col-6">
                                        <h5 className="lis-title">Out-Time</h5>
                                        <h5 className="lis-content">
                                          {formatTimeFromAPI(leaveItem.timeout)}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="listing-timing d-flex mb-2">
                                      <div className="intime col-6">
                                        <h5 className="lis-title">
                                          Created At
                                        </h5>
                                        <h5 className="lis-content">
                                          {formatDate(leaveItem.created_at)}
                                        </h5>
                                      </div>
                                      <div className="outtime3 col-6">
                                        <h5 className="lis-title">
                                          Updated Date
                                        </h5>
                                        <h5 className="lis-content">
                                          {formatDate(leaveItem.updated_at)}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="listing-desc">
                                      <h5 className="lis-title">Reason</h5>
                                      <h5 className="lis-content">
                                        {leaveItem.reason}
                                      </h5>
                                    </div>
                                    <div className="app-rejt mt-5 d-flex justify-content-between">
                                      <button
                                        onClick={() =>
                                          handleApprovedTime(
                                            leaveItem.id,
                                            'Approved',
                                            leaveItem.serialnumber
                                          )
                                        }
                                        className="btn btn-approve"
                                      >
                                        Approve
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleOpen(
                                            leaveItem.id,
                                            leaveItem.serialnumber
                                          )
                                        }
                                        className="btn btn-reject"
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion>
                            </div>
                          </div>
                        )
                    )
                  ) : (
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <Typography>No Pending</Typography>
                    </div>
                  )}
                </Tab>

                <Tab eventKey="History" title="History">
                  {historyData && historyData.length > 0 ? (
                    historyData.map(
                      (leaveItem, index) =>
                        leaveItem.status > '0' && (
                          <div key={index} className="row leave-item">
                            <div className="col-sm-12 row history-row position-relative">
                              <Accordion className="accord accord-2">
                                <Accordion.Header>
                                  <div className="leave-icon">
                                    {leaveItem.status == '1' ? (
                                      <div className="leave-approved">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M22 4L12 14.01L9 11.01"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    ) : (
                                      <div className="leave-reject">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M15 9L9 15"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M9 9L15 15"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    )}
                                  </div>
                                  <div className="leave-details">
                                    <h4>{leaveItem.username}</h4>
                                    <p className="date pending">
                                      <span>{formatDate(leaveItem.date)}</span>{' '}
                                    </p>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="listing-body">
                                    <div className="listing-timing d-flex mb-2">
                                      <div className="intime col-6">
                                        <h5 className="lis-title">
                                          Old In-Time
                                        </h5>
                                        <h5 className="lis-content">
                                          {formatTimeFromAPI(
                                            leaveItem.oldtimein
                                          )}
                                        </h5>
                                      </div>
                                      <div className="outtime col-6">
                                        <h5 className="lis-title">
                                          Old Out-Time
                                        </h5>
                                        <h5 className="lis-content">
                                          {formatTimeFromAPI(
                                            leaveItem.oldtimeout
                                          )}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="listing-timing d-flex mb-2">
                                      <div className="intime col-6">
                                        <h5 className="lis-title">In Time</h5>
                                        <h5 className="lis-content">
                                          {formatTimeFromAPI(leaveItem.timein)}
                                        </h5>
                                      </div>
                                      <div className="outtime2 col-6">
                                        <h5 className="lis-title">Out-Time</h5>
                                        <h5 className="lis-content">
                                          {formatTimeFromAPI(leaveItem.timeout)}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="listing-timing d-flex mb-2">
                                      <div className="intime col-6">
                                        <h5 className="lis-title">
                                          Created At
                                        </h5>
                                        <h5 className="lis-content">
                                          {formatDate(leaveItem.created_at)}
                                        </h5>
                                      </div>
                                      <div className="outtime col-6">
                                        <h5 className="lis-title">
                                          Updated Date
                                        </h5>
                                        <h5 className="lis-content">
                                          {formatDate(leaveItem.updated_at)}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="listing-desc">
                                      <h5 className="lis-title">Reason</h5>
                                      <h5 className="lis-content">
                                        {leaveItem.reason}
                                      </h5>
                                    </div>
                                    {leaveItem.reject_reason && (
                                      <div className="listing-desc">
                                        <h5 className="lis-title">
                                          Reason for Rejection
                                        </h5>
                                        <h5 className="lis-content">
                                          {leaveItem.reject_reason}
                                        </h5>
                                      </div>
                                    )}
                                  </div>
                                </Accordion.Body>
                              </Accordion>
                            </div>
                          </div>
                        )
                    )
                  ) : (
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <Typography>No History</Typography>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </Container>

          <Link to="/apply-leave"></Link>
        </section>
        <div>
          <Modal className="modalReject" show={open} onHide={handleClose}>
            <Modal.Body className="modal-body2">
              <Grid container>
                <Grid item xs={12}>
                  <label
                    style={{
                      fontSize: '20px',
                      fontWeight: 600,
                      marginRight: 'auto',
                    }}
                    className="mb-3"
                  >
                    Reason
                  </label>
                  <CloseIcon />
                  <Form.Control
                    as="textarea"
                    placeholder="Enter rejection reason"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    style={{
                      width: '145px',
                      height: '45px',
                      borderRadius: '10px',
                      backgroundColor: '#FE3636',
                      border: '15px',
                      color: '#FFFFFF',
                    }}
                    onClick={() =>
                      handleApprovedTime(
                        selectedLeaveId,
                        'Rejected',
                        selectedLeaveSerial
                      )
                    }
                  >
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </>
  )
}

export default Requests
