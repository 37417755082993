// import React, { useEffect, useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import '../css/custom.css'
// import '../css/style.css'
// import { useNavigate } from 'react-router-dom'
// import backendApi from '../helper.js'

// const Apply_leave = () => {
//   useEffect(() => {})

//   const navigate = useNavigate()
//   const [formData, setFormData] = useState({
//     leave_type: '',
//     reason: '',
//     from_date: '',
//     to_date: '',
//     day: '',
//   })

//   const handleInputChange = (event) => {
//     const { name, value } = event.target
//     setFormData({
//       ...formData,
//       [name]: value,
//       day: value === 'full-day' ? '1' : '2',
//     })
//   }

//   const handleRadioChange = (event) => {
//     const { name, value } = event.target
//     setFormData({
//       ...formData,
//       day: value,
//     })
//   }

//   const handleSubmit = async (event) => {
//     event.preventDefault()
//     if (
//       !formData.leave_type ||
//       !formData.reason ||
//       !formData.from_date ||
//       !formData.to_date ||
//       !formData.day
//     ) {
//       alert('Please fill in all fields')
//       return
//     }

//     const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))

//     const data = {
//       action: 'add_leave_by_id',
//       id: hrms_user_id.serialnumber,
//       leave_type: formData.leave_type,
//       reason: formData.reason,
//       from_date: formData.from_date,
//       to_date: formData.to_date,
//       day: formData.day,
//     }

//     try {
//       const response = await backendApi(data)
//       console.log('API Response:', response)

//       if (!response) {
//         throw new Error('Empty response from server')
//       }
//       console.log('Leave submitted successfully:', response)
//       alert('Success')

//       navigate('/Leave')
//     } catch (error) {
//       console.error('Error submitting leave:', error)
//       alert('Error submitting leave. Please try again.')
//     }
//   }
//   return (
//     <>
//       <section class="apply-page">
//         <section class="apply-form-block">
//           <div class="container">
//             <form action="" onSubmit={handleSubmit}>
//               <div class="form-group">
//                 <div class="col-lg-12">
//                   <label for="">Types of Leave</label>
//                 </div>
//                 <div class="col-lg-12">
//                   <select
//                     name="leave_type"
//                     value={formData.leave_type}
//                     onChange={handleInputChange}
//                   >
//                     <option value=""> --Select--</option>
//                     <option value="Absent due to sickness">
//                       Absent due to sickness
//                     </option>
//                     <option value="Appointment with doctor">
//                       Appointment with doctor
//                     </option>
//                     <option value="Casual">Casual</option>
//                     <option value="Family reason">Family reason</option>
//                     <option value="Family problem">Family problem</option>
//                     <option value="Going home town">Going home town</option>
//                     <option value="Hospitalisation">Hospitalisation</option>
//                     <option value="Personal Reason">Personal Reason</option>
//                     <option value="Personal Work">Personal Work</option>
//                     <option value="To attend a family function">
//                       To attend a family function
//                     </option>
//                     <option value="To attend college">To attend college</option>
//                     <option value="Sick">Sick</option>
//                   </select>
//                 </div>
//               </div>
//               <div class="form-group">
//                 <div class="col-lg-12">
//                   <label for="">Reason</label>
//                 </div>
//                 <div class="col-lg-12">
//                   <textarea
//                     name="reason"
//                     id=""
//                     value={formData.reason}
//                     onChange={handleInputChange}
//                     rows="4"
//                     placeholder="Enter Reason"
//                   ></textarea>
//                 </div>
//               </div>
//               <div class="form-group col-lg-12 d-flex justify-content-between">
//                 <div class="col-6" style={{ paddingRight: '10px' }}>
//                   <div class="col-12">
//                     <label for="">From Date</label>
//                   </div>
//                   <div class="col-12">
//                     <input
//                       type="date"
//                       name="from_date"
//                       id=""
//                       min={new Date().toISOString().split('T')[0]}
//                       value={formData.from_date}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>
//                 <div class="col-6" style={{ paddingLeft: '10px' }}>
//                   <div class="col-12">
//                     <label for="">To Date</label>
//                   </div>
//                   <div class="col-12">
//                     <input
//                       type="date"
//                       name="to_date"
//                       id=""
//                       value={formData.to_date}
//                       onChange={handleInputChange}
//                       min={formData.from_date}
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div class="form-group col-lg-12 d-flex justify-content-between">
//                 {/* <!-- <div class=""> --> */}
//                 <div class="col-6">
//                   <div class="col-12" style={{ paddingRight: '10px' }}>
//                     <input
//                       type="radio"
//                       name="day"
//                       id="half-day"
//                       value="2"
//                       checked={formData.day === '2'}
//                       onChange={handleRadioChange}
//                     />
//                     <label for="half-day" class="half-day">
//                       Half-day
//                     </label>
//                   </div>
//                 </div>
//                 <div class="col-6">
//                   <div class="col-12" style={{ paddingLeft: '10px' }}>
//                     <input
//                       type="radio"
//                       name="day"
//                       id="full-day"
//                       value="1"
//                       checked={formData.day === '1'}
//                       onChange={handleRadioChange}
//                     />
//                     <label for="full-day" class="full-day">
//                       full-day
//                     </label>
//                   </div>
//                 </div>
//                 {/* <!-- </div> --> */}
//               </div>
//               <div class="form-group">
//                 <input type="submit" value="Submit" class="form-submit-btn" />
//               </div>
//             </form>
//           </div>
//         </section>
//       </section>
//     </>
//   )
// }

// export default Apply_leave

import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'
import '../css/style.css'
import { useNavigate } from 'react-router-dom'
import backendApi from '../helper.js'
import Toastinette from '../js/toastinette.js'
import { AUTOCLOSE, POSITION, PROGRESS } from '../constant.js'
import Loader from '../js/loader.js'

const Apply_leave = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    leave_type: '',
    reason: '',
    from_date: '',
    to_date: '',
    day: '',
  })
  const [daysDifference, setDaysDifference] = useState(0)

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (formData.from_date && formData.to_date) {
      const fromDate = new Date(formData.from_date)
      const toDate = new Date(formData.to_date)
      const differenceInTime = toDate.getTime() - fromDate.getTime()
      const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1
      setDaysDifference(differenceInDays)

      if (differenceInDays > 1) {
        setFormData((prevData) => ({
          ...prevData,
          day: '1',
        }))
      }
    }
  }, [formData.from_date, formData.to_date])

  const handleRadioChange = (event) => {
    const { value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      day: value,
    }))
  }

  const handleSubmit = async (event) => {
    Loader.open()
    event.preventDefault()
    if (
      !formData.leave_type ||
      !formData.reason ||
      !formData.from_date ||
      !formData.to_date ||
      !formData.day
    ) {
      Toastinette.init({
        position: POSITION,
        title: 'Oops..!',
        message: 'Please fill all the fields!',
        type: 'warning',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      Loader.close()
      return
    }

    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))

    const data = {
      action: 'add_leave_by_id',
      id: hrms_user_id.serialnumber,
      leave_type: formData.leave_type,
      reason: formData.reason,
      from_date: formData.from_date,
      to_date: formData.to_date,
      day: formData.day,
    }

    try {
      const response = await backendApi(data, hrms_user_id.user_auth)
      console.log('API Response:', response)

      if (!response) {
        throw new Error('Empty response from server')
      }
      console.log('Leave submitted successfully:', response)
      Toastinette.init({
        position: POSITION,
        title: 'Congratulations',
        message: response.message,
        type: response.type,
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      Loader.close()
      navigate('/Leave')
    } catch (error) {
      console.error('Error submitting leave:', error)
      Toastinette.init({
        position: POSITION,
        title: 'Oops..!',
        message: 'Error submitting leave. Please try again!',
        type: 'warning',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      Loader.close()
    }
  }

  const [minDate, setMinDate] = useState('')

  useEffect(() => {
    const today = new Date()
    const formattedDate = today.toISOString().split('T')[0] // YYYY-MM-DD format
    setMinDate(formattedDate)
  }, [])

  return (
    <>
      <section className="apply-page">
        <section className="apply-form-block">
          <div className="container">
            <form action="" onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="col-lg-12">
                  <label>Types of Leave</label>
                </div>
                <div className="col-lg-12">
                  <select
                    name="leave_type"
                    value={formData.leave_type}
                    onChange={handleInputChange}
                  >
                    <option value=""> --Select--</option>
                    <option value="Absent due to sickness">
                      Absent due to sickness
                    </option>
                    <option value="Appointment with doctor">
                      Appointment with doctor
                    </option>
                    <option value="Casual">Casual</option>
                    <option value="Family reason">Family reason</option>
                    <option value="Family problem">Family problem</option>
                    <option value="Going home town">Going home town</option>
                    <option value="Hospitalisation">Hospitalisation</option>
                    <option value="Personal Reason">Personal Reason</option>
                    <option value="Personal Work">Personal Work</option>
                    <option value="To attend a family function">
                      To attend a family function
                    </option>
                    <option value="To attend college">To attend college</option>
                    <option value="Sick">Sick</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="col-lg-12">
                  <label>Reason</label>
                </div>
                <div className="col-lg-12">
                  <textarea
                    name="reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                    rows="4"
                    placeholder="Enter Reason"
                  ></textarea>
                </div>
              </div>
              <div className="form-group col-lg-12 d-flex justify-content-between">
                <div className="col-6" style={{ paddingRight: '10px' }}>
                  <div className="col-12">
                    <label>From Date</label>
                  </div>
                  <div className="col-12">
                    <input
                      type="date"
                      name="from_date"
                      // min={new Date().toISOString().split('T')[0]}
                      value={formData.from_date}
                      onChange={handleInputChange}
                      min={minDate}
                    />
                  </div>
                </div>
                <div className="col-6" style={{ paddingLeft: '10px' }}>
                  <div className="col-12">
                    <label>To Date</label>
                  </div>
                  <div className="col-12">
                    <input
                      type="date"
                      name="to_date"
                      value={formData.to_date}
                      onChange={handleInputChange}
                      min={formData.from_date}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-12 d-flex justify-content-between">
                <div className="col-6">
                  <div className="col-12" style={{ paddingRight: '10px' }}>
                    <input
                      type="radio"
                      name="day"
                      id="half-day"
                      value="2"
                      checked={formData.day === '2'}
                      onChange={handleRadioChange}
                      disabled={daysDifference > 1}
                      style={daysDifference > 1 ? { color: 'grey' } : {}}
                    />
                    <label
                      htmlFor="half-day"
                      className="half-day"
                      style={
                        daysDifference > 1
                          ? { color: 'grey', borderColor: 'grey' }
                          : {}
                      }
                    >
                      Half-day
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="col-12" style={{ paddingLeft: '10px' }}>
                    <input
                      type="radio"
                      name="day"
                      id="full-day"
                      value="1"
                      checked={formData.day === '1'}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="full-day" className="full-day">
                      Full-day
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  value="Submit"
                  className="form-submit-btn"
                  style={{
                    backgroundColor: '#21CD12',
                    color: '#FFFFFF',
                    border: 'none',
                    borderRadius: '25px',
                  }}
                />
              </div>
            </form>
          </div>
        </section>
      </section>
    </>
  )
}

export default Apply_leave
