import axios from 'axios'
import CryptoJS from 'crypto-js'
import { PAYLOADKEY } from './constant'

const configHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
  //"Accept": "application/json"
}

function backendApi(payload, token) {
  const formData = new FormData()
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key])
  })
  const url1 = 'https://hrms.mytecsys.in/api/extensionApi.php'
  // const url1 = 'http://localhost/hrms.mytecsys.in/api/extensionApi.php'

  return axios
    .post(url1, formData, {
      headers: token
        ? {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: token,
          }
        : { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export default backendApi
