import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Loader from '../../js/loader'
import backendApi from '../../helper'
import { useLocation, useNavigate } from 'react-router-dom'

function Add_perfor() {
  const navigate = useNavigate()
  const location = useLocation()
  const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
  const queryParams = new URLSearchParams(location.search)
  var serialnumber = queryParams.get('serialnumber')
  serialnumber = serialnumber.replace(/ /g, '+')
  const [formData, setFormData] = useState({
    remark_date: '',
    category: '',
    remark: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      Loader.open() // Show loader
      const data = {
        action: 'add_performance',
        serialnumber,
        ...formData, // Include form data in the request
      }

      const result = await backendApi(data, hrms_user_id.user_auth) // Send POST request to backend

      Loader.close() // Hide loader

      if (!result.error) {
        console.log('Performance data added successfully:', result.message)
        navigate('/performance')
        // Optionally, you can update state or perform any other action here
      } else {
        console.log('Failed to add performance data:', result.message)
        // Handle error scenario, show error message, etc.
      }
    } catch (error) {
      Loader.close() // Hide loader on error
      console.log('Error during API call:', error)
      // Handle error, show error message, etc.
    }

    // After submission, close the modal
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formDate">
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="date"
          name="remark_date"
          value={formData.remark_date}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="formStatus">
        <Form.Label>Status</Form.Label>
        <Form.Select
          name="category"
          value={formData.category}
          onChange={handleChange}
          required
        >
          <option value="1">Appreciation</option>
          <option value="2">Disappointed</option>
          <option value="3">Escalation</option>
        </Form.Select>
      </Form.Group>

      <Form.Group controlId="formDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          name="remark"
          value={formData.remark}
          onChange={handleChange}
          rows={3}
          required
        />
      </Form.Group>
      <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          style={{
            marginTop: '10px',
            padding: '5px 30px',
            color: '#ffffff',
            backgroundColor: '#21CD12',
          }}
        >
          Submit
        </Button>
      </Form.Group>
    </Form>
  )
}

export default Add_perfor
