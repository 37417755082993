import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/custom.css'
import '../../css/style.css'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import backendApi from '../../helper'
import Loader from '../../js/loader'

const Month_Att = () => {
  const location = useLocation()
  const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
  const queryParams = new URLSearchParams(location.search)
  var serialnumber = queryParams.get('serialnumber')
  serialnumber = serialnumber.replace(/ /g, '+')
  const month = queryParams.get('Month')
  const year = queryParams.get('year')
  const [monthWiseData, setMonthWiseData] = useState([])
  const [logData, setLogData] = useState([])
  const [leave, setLeave] = useState()

  useEffect(() => {
    console.log('serialnumber:', serialnumber) // Debug log

    const fetchMonthWiseData = async () => {
      if (!serialnumber || !month || !year) {
        console.log('Serial number or month not provided')
        return
      }

      Loader.open()
      const data = {
        action: 'fetch_monthwise_data',
        serialnumber,
        month,
        year,
      }

      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        Loader.close()
        if (!result.error) {
          setMonthWiseData(result.payroll_data)
          setLogData(result.user_logs_data)
          setLeave(result.approved_leave_data)
        } else {
          console.log('Failed to fetch month-wise data:', result.message)
        }
      } catch (error) {
        Loader.close()
        console.log('Error during API call:', error)
      }
    }

    fetchMonthWiseData()
  }, [serialnumber, month, year])

  const getAutoDayEndCount = () => {
    // Calculate the count of entries where type >= 1
    return logData.filter((log) => parseInt(log.type) == 1).length
  }

  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return months[monthNumber - 1]
  }

  const formatTimeFromAPI = (time) => {
    if (!time) return ''
    const [hours, minutes] = time.split(':')
    const formattedTime = new Date()
    formattedTime.setHours(hours)
    formattedTime.setMinutes(minutes)
    return formattedTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  function formatDateFromAPI(apiDateString) {
    const dateObject = new Date(apiDateString)
    if (!(dateObject instanceof Date) || isNaN(dateObject)) {
      return 'Invalid Date'
    }
    const day = dateObject.getDate()
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
      dateObject
    )
    const year = dateObject.getFullYear()
    return `${day} ${month} ${year}`
  }

  return (
    <>
      <Container fluid className="hrms_content">
        <Row>
          <Col lg={12} className="p-0 mt-2">
            <Col lg={12} className="p-0 mb-3">
              <div className="profile-title">
                <h4 className="font-weight-bold m-0" style={{ lineHeight: 1 }}>
                  {getMonthName(month)} {new Date().getFullYear()}
                </h4>
              </div>
            </Col>
            {logData && logData.length > 0
              ? logData.map((logs, index) => (
                  <>
                    <div class="leave-item" key={index}>
                      <div class="col-sm-12 row history-row align-items-center">
                        <div class="col-sm-1">
                          <div class="icon-dis">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16 2V6"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8 2V6"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3 10H21"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="col-sm-8">
                          <h5 class="his-date">
                            <span style={{ color: '#000' }}>
                              {formatDateFromAPI(logs.checkindate)}
                            </span>
                          </h5>
                          <p class="his-time m-0">
                            {/* <span style={{ color: '#21CD12' }}>
                              {new Date(
                                `1970-01-01T${logs.timein}Z`
                              ).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                              })}
                            </span>{' '}
                            -{' '}
                            <span style={{ color: '#B3B0ED' }}>
                              {new Date(
                                `1970-01-01T${logs.timeout}Z`
                              ).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                              })}
                            </span> */}

                            {logs.type == 0 || logs.type == 1 ? (
                              logs.timeout === '00:00:00' ? (
                                logs.type == 1 ? (
                                  <div>
                                    <span style={{ color: '#21CD12' }}>
                                      {formatTimeFromAPI(logs.timein)}
                                    </span>{' '}
                                    -{' '}
                                    <span style={{ color: '#B3B0ED' }}>
                                      Auto Dayend
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <span style={{ color: '#21CD12' }}>
                                      {formatTimeFromAPI(logs.timein)}
                                    </span>{' '}
                                    -{' '}
                                    <span style={{ color: '#B3B0ED' }}>
                                      Pending
                                    </span>
                                  </div>
                                )
                              ) : (
                                <div>
                                  <span style={{ color: '#21CD12' }}>
                                    {formatTimeFromAPI(logs.timein)}
                                  </span>{' '}
                                  -{' '}
                                  <span style={{ color: '#21CD12' }}>
                                    {formatTimeFromAPI(logs.timeout)}
                                  </span>
                                </div>
                              )
                            ) : logs.type == 2 ? (
                              <span style={{ color: '#F59F39' }}>Absent</span>
                            ) : (
                              <span style={{ color: '#FE3636' }}>Holiday</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : 'No Log Data'}
          </Col>
        </Row>
      </Container>
      {monthWiseData && monthWiseData.length > 0
        ? monthWiseData.map((payroll, index) => (
            <>
              <Row className="att-bottom" key={index}>
                <Col
                  lg={12}
                  className="d-flex flex-wrap justify-content-between"
                >
                  <Col lg={6}>
                    <h6 style={{ fontSize: '13px' }}>
                      <span style={{ fontWeight: 'bolder' }}>Working days</span>{' '}
                      : {monthWiseData[0]?.no_of_days_in_month} Days
                    </h6>
                  </Col>
                  <Col lg={6}>
                    <h6 style={{ fontSize: '13px' }}>
                      <span style={{ fontWeight: 'bolder' }}>Auto Day End</span>{' '}
                      : {monthWiseData[0]?.autoday_end} Days
                    </h6>
                  </Col>
                  <Col lg={6}>
                    <h6 style={{ fontSize: '13px' }}>
                      <span style={{ fontWeight: 'bolder' }}>Present days</span>{' '}
                      : {monthWiseData[0]?.days_present} Days
                    </h6>
                  </Col>
                  <Col lg={6}>
                    <h6 style={{ fontSize: '13px' }}>
                      <span style={{ fontWeight: 'bolder' }}>Late Entry</span> :{' '}
                      {monthWiseData[0]?.late_entry} Days
                    </h6>
                  </Col>
                  <Col lg={6}>
                    <h6 style={{ fontSize: '13px' }}>
                      <span style={{ fontWeight: 'bolder' }}>Total Leaves</span>{' '}
                      : {leave} Days
                    </h6>
                  </Col>
                  <Col lg={6}>
                    <h6 style={{ fontSize: '13px' }}>
                      <span style={{ fontWeight: 'bolder' }}>Working Hour</span>{' '}
                      : {monthWiseData[0]?.total_hours} hr
                    </h6>
                  </Col>
                </Col>
              </Row>
            </>
          ))
        : 'No Log Data'}
    </>
  )
}

export default Month_Att
