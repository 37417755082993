// import React, { useState, useRef, useEffect } from 'react'
// import logo from '../../img/logo.png'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import backendApi from '../../helper.js'
// import Toastinette from '../../js/toastinette.js'
// import Loader from '../../js/loader.js'
// import './login.css'
// import { useNavigate } from 'react-router-dom'
// import { AUTOCLOSE, POSITION, PROGRESS } from '../../constant.js'

// const Login = () => {
//   const navigate = useNavigate()
//   const [email, setEmail] = useState('')
//   const [isValidEmail, setIsValidEmail] = useState(true)
//   const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', ''])
//   const [visibleButton, setVisibleButton] = useState(false)
//   const [visiButton, setVisiButton] = useState(false)
//   const [timer, setTimer] = useState(0)
//   const [errorMessage, setErrorMessage] = useState('')
//   const [isLoggedIn, setIsLoggedIn] = useState(false)
//   const [isLoading, setIsLoading] = useState(false)
//   const [isdisable, setIsdisable] = useState(false)
//   const [emailChanged, setEmailChanged] = useState(false)
//   const [otpSent, setOtpSent] = useState(false)
//   const [mapLoaded, setMapLoaded] = useState(false)
//   const inputRefs = useRef([])

//   useEffect(() => {
//     if (timer > 0) {
//       const interval = setInterval(() => {
//         setTimer((prevTimer) => prevTimer - 1)
//       }, 1000)
//       return () => clearInterval(interval)
//     }
//   }, [timer])

//   useEffect(() => {
//     checkEmailAlreadyAvailable()
//   }, [])

//   const checkEmailAlreadyAvailable = () => {
//     if (localStorage.getItem('login_email') !== null) {
//       setEmail(localStorage.getItem('login_email'))
//       setOtpSent(true)
//     }
//   }

//   useEffect(() => {
//     const checkLoginStatus = () => {
//       if (JSON.parse(localStorage.getItem('user_Data')) === null) {
//         setIsLoggedIn(false)
//       } else {
//         setIsLoggedIn(true)
//       }
//     }

//     checkLoginStatus()
//   }, [])
//   const togglePages = () => {
//     setIsLoggedIn(!isLoggedIn)
//   }

//   const validateEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
//     return emailRegex.test(email)
//   }
//   const handleInputChange = (event) => {
//     const newEmail = event.target.value
//     setEmail(newEmail)

//     if (!validateEmail(newEmail)) {
//       setIsValidEmail(false)
//     } else {
//       setIsValidEmail(true)
//     }
//   }

//   const handleOtpDigitChange = (index, e) => {
//     const newOtpDigits = [...otpDigits]
//     newOtpDigits[index] = e.target.value
//     setOtpDigits(newOtpDigits)

//     const { maxLength, value } = e.target
//     if (
//       value.length === maxLength &&
//       index < otpDigits.length - 1 &&
//       inputRefs.current[index + 1]
//     ) {
//       inputRefs.current[index + 1].focus()
//       inputRefs.current[index + 1].setSelectionRange(0, 0)
//     }
//     if (value.length === 0 && index > 0 && inputRefs.current[index - 1]) {
//       inputRefs.current[index - 1].focus()
//       inputRefs.current[index - 1].setSelectionRange(maxLength, maxLength)
//     }

//     setVisiButton(true)
//     setIsdisable(false)
//   }
//   const handlePaste = (e) => {
//     const pasteData = e.clipboardData.getData('text').slice(0, otpDigits.length)
//     const newOtpDigits = pasteData.split('')
//     setOtpDigits(newOtpDigits)
//     newOtpDigits.forEach((digit, index) => {
//       if (inputRefs.current[index]) {
//         inputRefs.current[index].value = digit
//       }
//     })
//     setVisiButton(true)
//     setIsdisable(false)
//   }

//   const handleLogin = async () => {
//     const mail = document.getElementById('emailinput').value
//     const istrue = validateEmail(mail)
//     setIsdisable(istrue)
//     if (istrue) {
//       try {
//         Loader.open()
//         const payload = {
//           action: 'send_user_email',
//           email: mail,
//         }
//         await backendApi(payload)
//           .then((result) => {
//             Loader.close()
//             if (result.error === false) {
//               Toastinette.init({
//                 position: POSITION,
//                 title: 'Congratulations',
//                 message: result.msg,
//                 type: result.type,
//                 autoClose: AUTOCLOSE,
//                 progress: PROGRESS,
//               })
//               Loader.close()
//               setTimer(60)

//               setVisibleButton(true)
//               setIsdisable(true)
//               setIsLoading(false)
//               console.log(visibleButton)
//             } else {
//               Toastinette.init({
//                 position: POSITION,
//                 title: 'Oops...!',
//                 message: result.msg,
//                 type: result.type,
//                 autoClose: AUTOCLOSE,
//                 progress: PROGRESS,
//               })
//               setIsdisable(false)
//               setVisibleButton(false)
//               setIsLoading(false)
//               Loader.close()
//             }
//           })
//           .catch(() => {
//             console.log('error')
//             setIsdisable(false)
//             setVisibleButton(false)
//             document.getElementById('emailinput').value = ''
//             Loader.close()
//           })
//       } catch (error) {
//         console.error('Error logging in:', error)
//         setErrorMessage('An error occurred while logging in. Please try again.')
//         Loader.close()
//       }
//     } else {
//       Toastinette.init({
//         position: POSITION,
//         title: 'Oops..',
//         message: 'please enter email',
//         type: 'warning',
//         autoClose: AUTOCLOSE,
//         progress: PROGRESS,
//       })
//       setIsdisable(false)
//       setVisibleButton(false)
//       console.log('not valid')
//     }
//   }

//   const handleVerifyOtpClick = () => {
//     const otp = otpDigits.join('')

//     if (otp.length === 6) {
//       const payload = { action: 'verify_user_otp', email: email, otp: otp }
//       backendApi(payload).then((result) => {
//         console.log(result)
//         if (result.error === false) {
//           var user_details = result['user_details']

//           Toastinette.init({
//             position: POSITION,
//             title: 'Congratulations',
//             message: result.msg,
//             type: result.type,
//             autoClose: AUTOCLOSE,
//             progress: PROGRESS,
//           })
//           const userdata = {
//             id: user_details[0].id,
//             username: user_details[0].username,
//             role: user_details[0].role,
//             gender: user_details[0].gender,
//             user_date: user_details[0].user_date,
//             time_in: user_details[0].time_in,
//             serialnumber: user_details[0].serialnumber,
//             dob: user_details[0].dob,
//             user_auth: user_details[0].user_token,
//           }
//           localStorage.setItem('user_Data', JSON.stringify(userdata))
//           setIsLoggedIn(true)
//           setOtpDigits(['', '', '', '', '', ''])
//           if (user_details[0].role == 'admin') {
//             navigate('/dashboard', { replace: true })
//           } else {
//             navigate('/attendance-page', { replace: true })
//           }
//         } else {
//           Toastinette.init({
//             position: POSITION,
//             title: 'Oops...!',
//             message: result.msg,
//             type: result.type,
//             autoClose: AUTOCLOSE,
//             progress: PROGRESS,
//           })
//           setOtpDigits(['', '', '', '', '', ''])
//         }
//       })
//     } else {
//       Toastinette.init({
//         position: POSITION,
//         title: 'Oops..',
//         message: 'Invalid OTP!',
//         type: 'warning',
//         autoClose: AUTOCLOSE,
//         progress: PROGRESS,
//       })
//       console.log('wrong')
//     }
//   }
//   const handlechange_email = () => {
//     setEmail('')
//     console.log(email)
//     setIsdisable(false)
//     setVisibleButton(false)
//   }

//   const handleKeyPress = (event, action) => {
//     if (event.key === 'Enter') {
//       console.log(action)
//       action()
//     }
//   }
//   return (
//     <section className="login-page pt-5">
//       <div className="container">
//         <div className="col-lg-12 row">
//           <img src={logo} alt="no" className="img-fluid" />
//           <div className="col-12 text-center mt-4 mb-3">
//             <h2 className="head-title ">Login to continue</h2>
//           </div>

//           <div className="col-12">
//             <input
//               type="email"
//               value={email}
//               id="emailinput"
//               className="form-control"
//               placeholder="Email address"
//               aria-describedby="passwordHelpBlock"
//               disabled={isdisable}
//               onChange={handleInputChange}
//               onKeyPress={(e) => handleKeyPress(e, handleLogin)}
//             />
//             {!isValidEmail && (
//               <p style={{ color: 'red' }}> Enter a valid email address</p>
//             )}
//           </div>

//           <div className="col-12 mt-4  text-center extra-added-btn d-flex flex-wrap">
//             {visibleButton && visibleButton === true ? (
//               <>
//                 <div className="justify-content-between col-12 mt-3 mr-10 ml-10 extra-added-btn d-flex">
//                   <div className="col-6 mr-3 text-center extra-added-btn">
//                     <button
//                       className="btn btn-outline-primary sentotpbtn"
//                       onClick={handleLogin}
//                       disabled={timer > 0}
//                     >
//                       {' '}
//                       {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
//                     </button>
//                   </div>
//                   <div className="col-6 mr-3 text-center extra-added-btn">
//                     <button
//                       className="btn btn-outline-primary  sentotpbtn "
//                       onClick={handlechange_email}
//                     >
//                       Change Email
//                     </button>
//                   </div>
//                 </div>

//                 <div className="col-12 text-center mt-4">
//                   <div className="pin digit-group">
//                     {otpDigits.map((digit, index) => (
//                       <input
//                         key={index}
//                         type="tel"
//                         name="otpdigit[]"
//                         maxLength="1"
//                         autoComplete="off"
//                         required
//                         pattern="\d{1}"
//                         value={digit}
//                         onChange={(e) => handleOtpDigitChange(index, e)}
//                         onPaste={handlePaste}
//                         ref={(ref) => (inputRefs.current[index] = ref)}
//                         style={{ display: 'inline-block' }}
//                         onKeyPress={(e) =>
//                           handleKeyPress(e, handleVerifyOtpClick)
//                         }
//                       />
//                     ))}
//                   </div>
//                 </div>
//               </>
//             ) : (
//               <button
//                 className="btn btn-outline-primary sentotpbtn mr-2 pr-1"
//                 disabled={!isValidEmail}
//                 onClick={handleLogin}
//               >
//                 {' '}
//                 {!mapLoaded ? 'Send OTP' : 'Send OTP'}
//               </button>
//             )}
//           </div>

//           <div className="col-12 mt-4 text-center ">
//             {visiButton && (
//               <button
//                 className="btn btn-outline-primary verifyotpbtn"
//                 onClick={handleVerifyOtpClick}
//                 style={{ display: 'inline-block' }}
//               >
//                 Verify OTP
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

// export default Login

import React, { useState, useRef, useEffect } from 'react'
import logo from '../../img/logo.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import backendApi from '../../helper.js'
import Toastinette from '../../js/toastinette.js'
import Loader from '../../js/loader.js'
import './login.css'
import { useNavigate } from 'react-router-dom'
import { AUTOCLOSE, POSITION, PROGRESS } from '../../constant.js'

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState(localStorage.getItem('login_email') || '')
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [otpDigits, setOtpDigits] = useState(['', '', '', '', '', ''])
  const [visibleButton, setVisibleButton] = useState(
    localStorage.getItem('visibleButton') === 'true'
  )
  const [visiButton, setVisiButton] = useState(false)
  const [timer, setTimer] = useState(Number(localStorage.getItem('timer')) || 0)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isdisable, setIsdisable] = useState(
    localStorage.getItem('isdisable') === 'true'
  )
  const [emailChanged, setEmailChanged] = useState(false)
  const [otpSent, setOtpSent] = useState(
    localStorage.getItem('otpSent') === 'true'
  )
  const [mapLoaded, setMapLoaded] = useState(false)
  const inputRefs = useRef([])

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [timer])

  useEffect(() => {
    if (localStorage.getItem('login_email')) {
      setOtpSent(true)
    }
  }, [])

  useEffect(() => {
    const checkLoginStatus = () => {
      if (JSON.parse(localStorage.getItem('user_Data')) === null) {
        setIsLoggedIn(false)
      } else {
        setIsLoggedIn(true)
      }
    }
    checkLoginStatus()
  }, [])

  useEffect(() => {
    localStorage.setItem('timer', timer)
  }, [timer])

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleInputChange = (event) => {
    const newEmail = event.target.value
    setEmail(newEmail)

    if (!validateEmail(newEmail)) {
      setIsValidEmail(false)
    } else {
      setIsValidEmail(true)
    }
  }

  const handleOtpDigitChange = (index, e) => {
    const newOtpDigits = [...otpDigits]
    newOtpDigits[index] = e.target.value
    setOtpDigits(newOtpDigits)

    const { maxLength, value } = e.target
    if (
      value.length === maxLength &&
      index < otpDigits.length - 1 &&
      inputRefs.current[index + 1]
    ) {
      inputRefs.current[index + 1].focus()
      inputRefs.current[index + 1].setSelectionRange(0, 0)
    }
    if (value.length === 0 && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus()
      inputRefs.current[index - 1].setSelectionRange(maxLength, maxLength)
    }

    setVisiButton(true)
    setIsdisable(false)
  }

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, otpDigits.length)
    const newOtpDigits = pasteData.split('')
    setOtpDigits(newOtpDigits)
    newOtpDigits.forEach((digit, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = digit
      }
    })
    setVisiButton(true)
    setIsdisable(false)
  }

  const handleLogin = async () => {
    const mail = document.getElementById('emailinput').value
    const istrue = validateEmail(mail)
    setIsdisable(istrue)
    if (istrue) {
      try {
        Loader.open()
        const payload = {
          action: 'send_user_email',
          email: mail,
        }
        await backendApi(payload)
          .then((result) => {
            Loader.close()
            if (result.error === false) {
              Toastinette.init({
                position: POSITION,
                title: 'Congratulations',
                message: result.msg,
                type: result.type,
                autoClose: AUTOCLOSE,
                progress: PROGRESS,
              })
              Loader.close()
              setTimer(60)

              setVisibleButton(true)
              localStorage.setItem('visibleButton', true)
              setIsdisable(true)
              localStorage.setItem('isdisable', true)
              setIsLoading(false)
              localStorage.setItem('login_email', mail)
              setOtpSent(true)
              localStorage.setItem('otpSent', true)
              console.log(visibleButton)
            } else {
              Toastinette.init({
                position: POSITION,
                title: 'Oops...!',
                message: result.msg,
                type: result.type,
                autoClose: AUTOCLOSE,
                progress: PROGRESS,
              })
              setIsdisable(false)
              localStorage.setItem('isdisable', false)
              setVisibleButton(false)
              localStorage.setItem('visibleButton', false)
              setIsLoading(false)
              Loader.close()
            }
          })
          .catch(() => {
            console.log('error')
            setIsdisable(false)
            localStorage.setItem('isdisable', false)
            setVisibleButton(false)
            localStorage.setItem('visibleButton', false)
            document.getElementById('emailinput').value = ''
            Loader.close()
          })
      } catch (error) {
        console.error('Error logging in:', error)
        setErrorMessage('An error occurred while logging in. Please try again.')
        Loader.close()
      }
    } else {
      Toastinette.init({
        position: POSITION,
        title: 'Oops..',
        message: 'please enter email',
        type: 'warning',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      setIsdisable(false)
      localStorage.setItem('isdisable', false)
      setVisibleButton(false)
      localStorage.setItem('visibleButton', false)
      console.log('not valid')
    }
  }

  const handleVerifyOtpClick = () => {
    const otp = otpDigits.join('')

    if (otp.length === 6) {
      const payload = { action: 'verify_user_otp', email: email, otp: otp }
      backendApi(payload).then((result) => {
        console.log(result)
        if (result.error === false) {
          var user_details = result['user_details']

          Toastinette.init({
            position: POSITION,
            title: 'Congratulations',
            message: result.msg,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          const userdata = {
            id: user_details[0].id,
            username: user_details[0].username,
            role: user_details[0].role,
            gender: user_details[0].gender,
            user_date: user_details[0].user_date,
            time_in: user_details[0].time_in,
            serialnumber: user_details[0].serialnumber,
            dob: user_details[0].dob,
            user_auth: user_details[0].user_token,
          }
          localStorage.setItem('user_Data', JSON.stringify(userdata))
          setIsLoggedIn(true)
          setOtpDigits(['', '', '', '', '', ''])
          if (user_details[0].role == 'admin') {
            navigate('/dashboard', { replace: true })
          } else {
            navigate('/attendance-page', { replace: true })
          }
        } else {
          Toastinette.init({
            position: POSITION,
            title: 'Oops...!',
            message: result.msg,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          setOtpDigits(['', '', '', '', '', ''])
        }
      })
    } else {
      Toastinette.init({
        position: POSITION,
        title: 'Oops..',
        message: 'Invalid OTP!',
        type: 'warning',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      console.log('wrong')
    }
  }
  const handlechange_email = () => {
    setEmail('')
    console.log(email)
    setIsdisable(false)
    localStorage.setItem('isdisable', false)
    setVisibleButton(false)
    localStorage.setItem('visibleButton', false)
    setOtpSent(false)
    setVisiButton(false)
    localStorage.removeItem('login_email')
    localStorage.setItem('otpSent', false)
  }

  const handleKeyPress = (event, action) => {
    if (event.key === 'Enter') {
      console.log(action)
      action()
    }
  }

  return (
    <section className="login-page pt-5">
      <div className="container">
        <div className="col-lg-12 row">
          <img src={logo} alt="no" className="img-fluid" />
          <div className="col-12 text-center mt-4 mb-3">
            <h2 className="head-title ">Login to continue</h2>
          </div>

          <div className="col-12">
            <input
              type="email"
              value={email}
              id="emailinput"
              className="form-control"
              placeholder="Email address"
              aria-describedby="passwordHelpBlock"
              disabled={isdisable}
              onChange={handleInputChange}
              onKeyPress={(e) => handleKeyPress(e, handleLogin)}
            />
            {!isValidEmail && (
              <p style={{ color: 'red' }}> Enter a valid email address</p>
            )}
          </div>

          <div className="col-12 mt-4  text-center extra-added-btn d-flex flex-wrap">
            {visibleButton && visibleButton === true ? (
              <>
                <div className="justify-content-between col-12 mt-3 mr-10 ml-10 extra-added-btn d-flex">
                  <div className="col-6 mr-3 text-center extra-added-btn">
                    <button
                      className="btn btn-outline-primary sentotpbtn"
                      onClick={handleLogin}
                      disabled={timer > 0}
                    >
                      {' '}
                      {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                    </button>
                  </div>
                  <div className="col-6 mr-3 text-center extra-added-btn">
                    <button
                      className="btn btn-outline-primary  sentotpbtn "
                      onClick={handlechange_email}
                    >
                      Change Email
                    </button>
                  </div>
                </div>

                <div className="col-12 text-center mt-4">
                  <div className="pin digit-group">
                    {otpDigits.map((digit, index) => (
                      <input
                        key={index}
                        type="tel"
                        name="otpdigit[]"
                        maxLength="1"
                        autoComplete="off"
                        required
                        pattern="\d{1}"
                        value={digit}
                        onChange={(e) => handleOtpDigitChange(index, e)}
                        onPaste={handlePaste}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        style={{ display: 'inline-block' }}
                        onKeyPress={(e) =>
                          handleKeyPress(e, handleVerifyOtpClick)
                        }
                      />
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <button
                className="btn btn-outline-primary sentotpbtn mr-2 pr-1"
                disabled={!isValidEmail}
                onClick={handleLogin}
              >
                {' '}
                {!mapLoaded ? 'Send OTP' : 'Send OTP'}
              </button>
            )}
          </div>

          <div className="col-12 mt-4 text-center ">
            {visiButton && (
              <button
                className="btn btn-outline-primary verifyotpbtn"
                onClick={handleVerifyOtpClick}
                style={{ display: 'inline-block' }}
              >
                Verify OTP
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
