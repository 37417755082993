import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/custom.css'
import '../../css/style.css'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import backendApi from '../../helper'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import maleImg from '../../img/R.jpg' // Update with correct path
import femaleImg from '../../img/user-8.png' // Update with correct path
import Loader from '../../js/loader'
import { AUTOCLOSE, POSITION, PROGRESS } from '../../constant'
import Toastinette from '../../js/toastinette'
import Swal from 'sweetalert2'

const Profiles2 = () => {
  const navigate = useNavigate()

  const [userDetails, setUserDetails] = useState([])
  const [d, setd] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    dob: '',
    salary: '',
    gender: '',
    designation: '',
    role: '',
    sd_deduction: '',
  })

  const handleClose = () => {
    setd(false)
    setEditMode(false)
    setSelectedUser(null)
    setNewUser({
      username: '',
      email: '',
      dob: '',
      salary: '',
      gender: '',
      designation: '',
      role: '',
      sd_deduction: '',
    })
  }

  const handleShowAdd = () => {
    setEditMode(false)
    setd(true)
  }

  const handleShowEdit = (user) => {
    setSelectedUser(user)
    setNewUser({
      username: user.username,
      email: user.email,
      dob: user.dob,
      salary: user.salary,
      gender: user.gender,
      designation: user.designation,
      role: user.role,
      sd_deduction: user.sd_deduction,
    })
    setEditMode(true)
    setd(true)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setNewUser((prevState) => ({ ...prevState, [name]: value }))
  }

  const validateForm = () => {
    const { username, email, dob, salary, gender, designation, role } = newUser
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const isEmailValid = emailPattern.test(email)
    const isSalaryValid = salary > 0

    if (
      !username ||
      !email ||
      !dob ||
      !salary ||
      !gender ||
      !designation ||
      !role
    ) {
      Toastinette.init({
        position: POSITION,
        title: 'Info...!',
        message: 'Please fill all the fields',
        type: 'warning',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      return false
    }
    if (!isEmailValid) {
      Toastinette.init({
        position: POSITION,
        title: 'Info...!',
        message: 'Please enter a valid email format',
        type: 'warning',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      return false
    }
    if (!isSalaryValid) {
      Toastinette.init({
        position: POSITION,
        title: 'Info...!',
        message: 'Please enter a valid salary',
        type: 'warning',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      return false
    }
    return true
  }

  const handleSave = async () => {
    if (!validateForm()) return

    if (editMode) {
      await handleEditUser()
    } else {
      await handleAddUser()
    }
  }

  const handleAddUser = async () => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'Add_staff',
        id: hrms_user_id.id,
        ...newUser,
      }
      console.log('Request payload:', data)
      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        console.log('Response from backend:', result)
        if (result.error === false) {
          fetchUserDetails()
          handleClose()
          Toastinette.init({
            position: POSITION,
            title: 'Congratulations',
            message: result.message,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
        } else {
          console.log('Failed to add user:', result.message || 'Unknown error')
          Toastinette.init({
            position: POSITION,
            title: 'Oops...!',
            message: result.message,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
        }
      } catch (error) {
        console.log('Error adding user:', error)
      }
    }
  }

  const handleEditUser = async () => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'edit_staff',
        id: selectedUser.id,
        ...newUser,
      }
      console.log('Edit request payload:', data)
      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        console.log('Edit response from backend:', result)
        if (result.error === false) {
          fetchUserDetails()
          handleClose()
          Toastinette.init({
            position: POSITION,
            title: 'Congratulations',
            message: result.message,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
        } else {
          console.log('Failed to edit user:', result.message || 'Unknown error')
          Toastinette.init({
            position: POSITION,
            title: 'Oops...!',
            message: result.message,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
        }
      } catch (error) {
        console.log('Error editing user:', error)
      }
    }
  }

  const handleDeleteUser = async (userId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
        if (hrms_user_id !== null) {
          const data = {
            action: 'delete_staff',
            id: userId,
          }
          console.log('Delete request payload:', data)
          try {
            const result_api = await backendApi(data, hrms_user_id.user_auth)
            console.log('Delete response from backend:', result_api)
            if (result_api.error === false) {
              Swal.fire({
                title: 'Deleted!',
                text: 'Staff has been deleted.',
                icon: 'success',
              })
              fetchUserDetails()
            } else {
              Swal.fire({
                title: 'Oops!',
                text: result_api.message,
                icon: 'error',
              })
            }
          } catch (error) {
            console.log('Error deleting user:', error)
          }
        }
      }
    })
  }

  useEffect(() => {
    fetchUserDetails()
  }, [])

  const fetchUserDetails = async () => {
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'fetch_all_staff',
        id: hrms_user_id.id,
      }
      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        if (
          result.error === false &&
          Array.isArray(result.user_Details) &&
          result.user_Details.length > 0
        ) {
          setUserDetails(result.user_Details)
        } else {
          console.log('No user found')
        }
      } catch (error) {
        console.log('Error fetching user data:', error)
      }
    }
  }

  return (
    <>
      <Container fluid className="hrms_content">
        <Row>
          <Col lg={12} className="p-0">
            <Col lg={12} className="p-0 mb-3">
              <div className="profile-title">
                <h4 className="m-0">Staff Lists</h4>
              </div>
            </Col>
            <Col lg={12} className="p-0">
              {userDetails.length > 0 ? (
                userDetails.map((profile, index) => (
                  <div className="row leave-item" key={index}>
                    <div className="col-sm-12 d-flex justify-content-between profile-row align-items-center">
                      <div className="profile-left col-10">
                        <div className="profile-icon">
                          <img
                            src={
                              profile.gender === 'Male' ? maleImg : femaleImg
                            }
                            alt=""
                            height={'60px'}
                            width={'60px'}
                          />
                        </div>
                        <div className="Profile-details">
                          <h4>{profile.username}</h4>
                          <p className="profile-designation m-0">
                            {profile.designation}
                          </p>
                        </div>
                      </div>
                      <div className="ml-4 mb-2">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleShowEdit(profile)}
                        >
                          <g clipPath="url(#clip0_386_1326)">
                            <path
                              d="M9.16663 3.33337H3.33329C2.89127 3.33337 2.46734 3.50897 2.15478 3.82153C1.84222 4.13409 1.66663 4.55801 1.66663 5.00004V16.6667C1.66663 17.1087 1.84222 17.5327 2.15478 17.8452C2.46734 18.1578 2.89127 18.3334 3.33329 18.3334H15C15.442 18.3334 15.8659 18.1578 16.1785 17.8452C16.491 17.5327 16.6666 17.1087 16.6666 16.6667V10.8334"
                              stroke="#4934B6"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4166 2.08332C15.7481 1.7518 16.1978 1.56555 16.6666 1.56555C17.1355 1.56555 17.5851 1.7518 17.9166 2.08332C18.2481 2.41484 18.434                              2.86448 18.4344 3.33332C18.4344 3.80216 18.2481 4.2518 17.9166 4.58332L9.99996 12.5L6.66663 13.3333L7.49996 9.99999L15.4166 2.08332Z"
                              stroke="#4934B6"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_386_1326">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <DeleteOutlineIcon
                          style={{ color: '#ef4343c7' }}
                          onClick={() => handleDeleteUser(profile.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h4>No User found</h4>
              )}
            </Col>
          </Col>
        </Row>
      </Container>

      <Modal show={d} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: '#DBE9F7' }}>
          <Typography variant="h6">{editMode ? 'Edit' : 'Add'} User</Typography>
        </Modal.Header>

        <Modal.Body
          style={{
            width: '100%',
            backgroundColor: '#DBE9F7',
            borderRadius: '10px',
          }}
        >
          <Grid container>
            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '15px', fontWeight: 600 }}>
                Username
              </label>
              <Form.Control
                type="name"
                name="username"
                value={newUser?.username}
                onChange={handleChange}
                required
                style={{
                  fontSize: '13px',
                  borderRadius: '10px',
                  border: 'none',
                  height: '40px',
                }}
              />
            </Grid>

            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '15px', fontWeight: 600 }}>Email</label>
              <Form.Control
                type="email"
                name="email"
                value={newUser?.email}
                onChange={handleChange}
                required
                style={{
                  fontSize: '13px',
                  borderRadius: '10px',
                  border: 'none',
                  height: '40px',
                }}
              />
            </Grid>

            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '15px', fontWeight: 600 }}>
                Date Of Birth
              </label>
              <Form.Control
                type="date"
                name="dob"
                value={newUser?.dob}
                onChange={handleChange}
                required
                style={{
                  fontSize: '13px',
                  borderRadius: '10px',
                  border: 'none',
                  height: '40px',
                }}
              />
            </Grid>

            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '15px', fontWeight: 600 }}>
                Salary
              </label>
              <Form.Control
                type="number"
                name="salary"
                value={newUser?.salary}
                onChange={handleChange}
                required
                style={{
                  fontSize: '13px',
                  borderRadius: '10px',
                  border: 'none',
                  height: '40px',
                }}
              />
            </Grid>
            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '15px', fontWeight: 600 }}>
                SD deduction
              </label>
              <Grid container>
                <Grid item xs={6}>
                  <Form.Check
                    type="radio"
                    name="sd_deduction"
                    value="1"
                    label="Yes"
                    checked={newUser?.sd_deduction == 1}
                    onChange={handleChange}
                    style={{ fontSize: '13px' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Form.Check
                    type="radio"
                    name="sd_deduction"
                    value="2"
                    label="No"
                    checked={newUser?.sd_deduction == 2}
                    onChange={handleChange}
                    style={{ fontSize: '13px' }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '15px', fontWeight: 600 }}>
                Gender
              </label>
              <Grid container>
                <Grid item xs={6}>
                  <Form.Check
                    type="radio"
                    name="gender"
                    value="Male"
                    label="Male"
                    checked={newUser?.gender === 'Male'}
                    onChange={handleChange}
                    style={{ fontSize: '13px' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Form.Check
                    type="radio"
                    name="gender"
                    value="Female"
                    label="Female"
                    checked={newUser?.gender === 'Female'}
                    onChange={handleChange}
                    style={{ fontSize: '13px' }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '15px', fontWeight: 600 }}>
                Designation
              </label>
              <Form.Control
                type="text"
                name="designation"
                value={newUser?.designation}
                onChange={handleChange}
                required
                style={{
                  fontSize: '13px',
                  borderRadius: '10px',
                  border: 'none',
                  height: '40px',
                }}
              />
            </Grid>

            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '15px', fontWeight: 600 }}>Role</label>
              <Form.Select
                name="role"
                value={newUser?.role}
                onChange={handleChange}
                required
                style={{
                  fontSize: '13px',
                  borderRadius: '10px',
                  border: 'none',
                  height: '40px',
                }}
              >
                <option value="" disabled>
                  Select Role
                </option>
                <option value="admin">Admin</option>
                <option value="hr">HR</option>
                <option value="staff">Staff</option>
              </Form.Select>
            </Grid>

            <Grid
              item
              xs={12}
              className="d-flex justify-content-center align-items-center mt-3"
            >
              <Button
                style={{
                  width: '100%',
                  height: '35px',
                  fontSize: '15px',
                  borderRadius: '10px',
                  border: 'none',
                  color: 'white',
                  backgroundColor: '#21CD12',
                }}
                onClick={handleSave}
              >
                {editMode ? 'Save' : 'Add'}
              </Button>
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal>

      <a
        onClick={handleShowAdd}
        style={{
          width: 'fit-content',
          position: 'absolute',
          right: '20px',
          bottom: '50px',
          zIndex: 9999,
        }}
        className="application-link"
      >
        <div className="leave-application-btn">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 5V19"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 12H19"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </a>
    </>
  )
}

export default Profiles2
