import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Form, Modal } from 'react-bootstrap'
import { Pie } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import '../../css/custom.css'
import backendApi from '../../helper'
import Loader from '../../js/loader'
import { useLocation } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUp'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning'
import { toast } from 'react-toastify'
import Toastinette from '../../js/toastinette'
import { AUTOCLOSE, POSITION, PROGRESS } from '../../constant'

Chart.register(ArcElement, Tooltip, Legend)

const Payslip = () => {
  const location = useLocation()
  const userData = JSON.parse(localStorage.getItem('user_Data'))
  const queryParams = new URLSearchParams(location.search)
  var serialnumber = queryParams.get('serialnumber')
  serialnumber = serialnumber.replace(/ /g, '+')
  const month = queryParams.get('Month')
  const year = queryParams.get('year')
  const [payslipData, setPayslipData] = useState([])
  const [leave, setLeave] = useState([])
  const [totalAmount, setTotalAmount] = useState('')

  // Update the data object in your component

  // Update the plugins and options in your Pie chart component

  // Update the cutout value in the options object to reduce the thickness of the pie chart
  // Adjust this value as needed to control the thickness
  const [performance, setPerformance] = useState([])
  const fetchPayslipData = async () => {
    if (!serialnumber || !month || !year) {
      console.log('Serial number, month, or year not provided')
      return
    }

    Loader.open()
    const data = {
      action: 'fetch_payslip_data',
      serialnumber,
      month,
      year,
    }

    try {
      const result = await backendApi(data, userData.user_auth)
      Loader.close()
      if (!result.error) {
        setPayslipData(result.payroll_data)
        setPerformance(result.remark_data)
        setLeave(result.approved_leave_data)
        setTotalAmount(result.payroll_data[0].total_salary)
      } else {
        console.log('Failed to fetch month-wise data:', result.message)
      }
    } catch (error) {
      Loader.close()
      console.log('Error during API call:', error)
    }
  }

  useEffect(() => {
    console.log('serialnumber:', serialnumber) // Debug log

    fetchPayslipData()
  }, [serialnumber, month, year])

  const data = {
    labels: ['Total Salary', 'Settlement'],
    datasets: [
      {
        data: [
          payslipData.length > 0 ? payslipData[0].total_salary : 0,
          payslipData.length > 0 ? payslipData[0].settlement : 0,
        ],
        backgroundColor: ['#21CD12', '#5450D6'],
        borderWidth: 1,
      },
    ],
  }

  // Update the options object to include tooltips showing the currency format
  // Update the options object to reduce the thickness of the pie chart
  const plugins = [
    {
      id: 'textCenter',
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx
        ctx.restore()

        const cutoutPercentage = chart.config.options.cutoutPercentage || 100
        const radius = (cutoutPercentage / 100) * (Math.min(width, height) / 2)

        const centerX = width / 2
        const centerY = height / 2

        // Adjust the radius to ensure the white background fits within the pie
        const adjustedRadius = radius * 0.9 // Adjust this factor as needed

        // Draw fixed white background
        ctx.fillStyle = 'white'
        ctx.beginPath()
        ctx.arc(centerX, centerY, adjustedRadius, 0, 2 * Math.PI)
        ctx.fill()
        const fontSize = '1.2rem'
        ctx.font = `${fontSize} sans-serif`
        ctx.textBaseline = 'middle'
        ctx.fillStyle = 'black'

        const text = `₹ ${totalAmount}`
        // const text = `₹ ${
        //   payslipData
        //     ? parseInt(payslipData[0]?.total_salary, 10) +
        //       parseInt(payslipData[0]?.settlement, 10)
        //     : 100000
        // }`
        const textX = Math.round((width - ctx.measureText(text).width) / 2)
        const textY = centerY - radius / 10 // Adding margin of 10% of the radius from the top

        ctx.fillText(text, textX, textY)
        ctx.save()
      },
    },
  ]

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'center',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || ''
            if (label) {
              label += ': '
            }
            if (context.raw !== null) {
              label += new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(context.raw)
            }
            return label
          },
        },
      },
    },
    maintainAspectRatio: false,
    cutout: '100%',
    cutoutPercentage: 100, // Adjust this value as needed
  }
  options.cutout = '85%'
  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return months[monthNumber - 1]
  }

  const [showModal, setShowModal] = useState(false)
  const [formData, setFormData] = useState({
    remark_date: '',
    category: '',
    remark: '',
  })

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const getMaxDate = () => {
    const today = new Date().toISOString().split('T')[0]
    return today
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let errors = {}
    if (!formData.remark_date) {
      errors.remark_date = 'Date is required'
    }
    if (!formData.category) {
      errors.category = 'Category is required'
    }
    if (!formData.remark) {
      errors.remark = 'Description is required'
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }

    try {
      Loader.open()
      const data = {
        action: 'add_performance',
        serialnumber,
        ...formData,
      }

      const result = await backendApi(data, userData.user_auth)

      if (!result.error) {
        console.log('Performance data added successfully:', result.message)
        // fetchPerformanceData()
        fetchPayslipData()
        setFormData({
          remark_date: '',
          category: '',
          remark: '',
        })
      } else {
        console.log('Failed to add performance data:', result.message)
        setFormErrors({
          apiError: 'Failed to add performance data. Please try again.',
        })
      }
    } catch (error) {
      console.log('Error during API call:', error)
      setFormErrors({ apiError: 'Error during API call. Please try again.' })
    } finally {
      Loader.close()
      handleClose()
    }
  }

  const [formErrors, setFormErrors] = useState({})

  const validateForm = (data) => {
    const errors = {}
    if (!data.remark_date) {
      errors.remark_date = 'Date is required'
    }
    if (!data.category) {
      errors.category = 'Category is required'
    }
    if (!data.remark) {
      errors.remark = 'Description is required'
    }
    return errors
  }

  // const fetchPerformanceData = async () => {
  //   if (!serialnumber) {
  //     console.log('Serial number not provided')
  //     return
  //   }

  //   Loader.open() // Assuming Loader has open and close methods for showing and hiding loader

  //   const data = {
  //     action: 'fetch_performance',
  //     serialnumber,
  //   }

  //   try {
  //     const result = await backendApi(data) // Assuming backendApi is a function that sends a request to your backend API
  //     Loader.close()

  //     if (!result.error) {
  //       setPerformance(result.performance)
  //     } else {
  //       console.log('Failed to fetch performance data:', result.message)
  //     }
  //   } catch (error) {
  //     Loader.close()
  //     console.log('Error during API call:', error)
  //   }
  // }

  // useEffect(() => {
  //   fetchPerformanceData()
  // }, [serialnumber])

  const handlePayNow = () => {
    if (
      payslipData &&
      payslipData.length > 0 &&
      payslipData[0].month &&
      payslipData[0].year
    ) {
      const { month, year } = payslipData[0]
      handlePayment(month, year)
    } else {
      console.log('Month and year information not available in payroll data')
    }
  }

  const handlePayment = async (month, year) => {
    try {
      Loader.open()
      const data = {
        action: 'add_payment',
        serialnumber,
        month,
        year,
        total_salary: payslipData[0]?.total_salary,
        no_of_days_in_month: payslipData[0]?.no_of_days_in_month,
        days_present: payslipData[0]?.days_present,
        days_absent: payslipData[0]?.days_absent,
        deduction: payslipData[0]?.leave_deduction,
        security_deposit: payslipData[0]?.security_deposit,
        incentive: payslipData[0]?.incentive,
        settlement: payslipData[0]?.settlement,
        settlement_month: payslipData[0]?.settlement_month,
        settlement_year: payslipData[0]?.settlement_year,
        net_salary: payslipData[0]?.net_amount || payslipData[0]?.net_salary,
      }

      const result = await backendApi(data, userData.user_auth)
      Loader.close()

      if (!result.error) {
        Toastinette.init({
          position: POSITION,
          title: 'Congratulations',
          message: 'Payment added successfully',
          type: 'success',
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        console.log('Payment added successfully:', result.message)
        fetchPayslipData()
      } else {
        Toastinette.init({
          position: POSITION,
          title: 'Oops...',
          message: 'Failed to add payment',
          type: 'error',
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        console.log('Failed to add Payment:', result.message)
      }
    } catch (error) {
      Loader.close()
      Toastinette.init({
        position: POSITION,
        title: 'Error',
        message: 'Error during payment process',
        type: 'error',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      console.log('Error during API call:', error)
    }
  }

  const borderColor =
    data.datasets[0].data[0] > data.datasets[0].data[1] ? '#5450D6' : '#21CD12'

  function formatDateFromAPI(apiDateString) {
    const dateObject = new Date(apiDateString)
    if (!(dateObject instanceof Date) || isNaN(dateObject)) {
      return 'Invalid Date'
    }
    const day = dateObject.getDate()
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
      dateObject
    )
    const year = dateObject.getFullYear()
    return `${day} ${month} ${year}`
  }

  return (
    <div className="hrms_content">
      <section className="payslip-list">
        <Container fluid style={{ overflow: 'auto' }}>
          <div className="chart">
            <div style={{ position: 'relative', height: '40vh' }}>
              <Pie options={options} data={data} plugins={plugins} />
            </div>
          </div>
          {payslipData.length > 0 ? (
            <Grid container>
              <Grid xs={6}>
                <Typography style={{ fontSize: '20px', fontWeight: 600 }}>
                  ₹ {payslipData[0].total_salary}
                </Typography>
                <Typography style={{ fontSize: '16px' }}>
                  Gross Amount
                </Typography>
              </Grid>

              <Grid xs={6} style={{ textAlign: 'end' }}>
                <Typography style={{ fontSize: '20px', fontWeight: 600 }}>
                  ₹ {payslipData[0].settlement}
                </Typography>
                <Typography style={{ fontSize: '16px' }}>Settlement</Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography>Loading...</Typography>
          )}
          <div className="col-lg-12 row mt-4">
            <Typography
              style={{ marginLeft: '-11px', fontSize: 25, fontWeight: 600 }}
            >
              Earning details
            </Typography>
            <Card>
              {payslipData.length > 0 ? (
                <Grid container>
                  <Grid xs={6} style={{ padding: '7px', textAlign: 'start' }}>
                    <Grid container>
                      <Grid xs={7}>
                        <Typography
                          variant=""
                          style={{ fontWeight: 600, fontSize: '12.12px' }}
                        >
                          Total Leaves{' '}
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        <span>:</span>
                      </Grid>
                      <Grid xs={4}>
                        <span style={{ fontSize: '12.12px' }}>
                          {leave} days
                        </span>
                      </Grid>

                      <Grid xs={7}>
                        <Typography
                          variant=""
                          style={{ fontWeight: 600, fontSize: '12.12px' }}
                        >
                          Paid Leaves{' '}
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        <span>:</span>
                      </Grid>
                      <Grid xs={4}>
                        <span style={{ fontSize: '12.12px' }}>01 days</span>
                      </Grid>

                      <Grid xs={7}>
                        <Typography
                          variant=""
                          style={{ fontWeight: 600, fontSize: '12.12px' }}
                        >
                          Working Hours{' '}
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        <span>:</span>
                      </Grid>
                      <Grid xs={4}>
                        <span style={{ fontSize: '12.12px' }}>
                          {payslipData[0]?.total_hours}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} style={{ padding: '7px', textAlign: 'end' }}>
                    <Grid container>
                      <Grid xs={7}>
                        <Typography
                          variant=""
                          style={{ fontWeight: 600, fontSize: '12.12px' }}
                        >
                          Late Entry{' '}
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        <span>:</span>
                      </Grid>
                      <Grid xs={4}>
                        <span style={{ fontSize: '12.12px' }}>
                          {payslipData[0]?.late_entry} days
                        </span>
                      </Grid>

                      <Grid xs={7}>
                        <Typography
                          variant=""
                          style={{ fontWeight: 600, fontSize: '12.12px' }}
                        >
                          Half Days{' '}
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        <span>:</span>
                      </Grid>
                      <Grid xs={4}>
                        <span style={{ fontSize: '12.12px' }}>
                          {payslipData[0]?.half_days} days
                        </span>
                      </Grid>

                      <Grid xs={7}>
                        <Typography
                          variant=""
                          style={{ fontWeight: 600, fontSize: '12.12px' }}
                        >
                          WFH{' '}
                        </Typography>
                      </Grid>
                      <Grid xs={1}>
                        <span>:</span>
                      </Grid>
                      <Grid xs={4}>
                        <span style={{ fontSize: '12.12px' }}>02 days</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Typography>Loading...</Typography>
              )}
            </Card>
            {!payslipData[0]?.current_month && (
              <Card className="mt-3">
                {payslipData.length > 0 ? (
                  <Grid container>
                    <Grid xs={6} style={{ padding: '7px', textAlign: 'start' }}>
                      <Typography variant="" style={{ fontSize: '16px' }}>
                        Gross
                      </Typography>
                    </Grid>
                    <Grid xs={6} style={{ padding: '7px', textAlign: 'end' }}>
                      <Typography
                        variant=""
                        style={{ fontSize: '20px', fontWeight: 600 }}
                      >
                        ₹ {payslipData[0]?.total_salary}
                      </Typography>
                    </Grid>

                    <Grid xs={6} style={{ padding: '7px', textAlign: 'start' }}>
                      <Typography variant="" style={{ fontSize: '16px' }}>
                        SD Deduction
                      </Typography>
                    </Grid>
                    <Grid xs={6} style={{ padding: '7px', textAlign: 'end' }}>
                      <Typography
                        variant=""
                        style={{ fontSize: '20px', fontWeight: 600 }}
                      >
                        ₹ {payslipData[0]?.security_deposit}
                      </Typography>
                    </Grid>

                    {payslipData[0]?.incentive > 0 ? (
                      <>
                        <Grid
                          xs={6}
                          style={{ padding: '7px', textAlign: 'start' }}
                        >
                          <Typography variant="" style={{ fontSize: '16px' }}>
                            Incentive
                          </Typography>
                        </Grid>
                        <Grid
                          xs={6}
                          style={{ padding: '7px', textAlign: 'end' }}
                        >
                          <Typography
                            variant=""
                            style={{ fontSize: '20px', fontWeight: 600 }}
                          >
                            ₹ {payslipData[0]?.incentive}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}

                    <Grid xs={6} style={{ padding: '7px', textAlign: 'start' }}>
                      <Typography variant="" style={{ fontSize: '16px' }}>
                        Leaves Deduction
                      </Typography>
                    </Grid>
                    <Grid xs={6} style={{ padding: '7px', textAlign: 'end' }}>
                      <Typography
                        variant=""
                        style={{ fontSize: '20px', fontWeight: 600 }}
                      >
                        ₹ {payslipData[0]?.leave_deduction}
                      </Typography>
                    </Grid>

                    <Grid xs={6} style={{ padding: '7px', textAlign: 'start' }}>
                      <Typography variant="" style={{ fontSize: '16px' }}>
                        Settlement{' '}
                        <small style={{ fontSize: '12px', fontWeight: 400 }}>
                          ({getMonthName(month)} {year})
                        </small>
                      </Typography>
                    </Grid>
                    <Grid xs={6} style={{ padding: '7px', textAlign: 'end' }}>
                      <Typography
                        variant=""
                        style={{ fontSize: '20px', fontWeight: 600 }}
                      >
                        ₹ {payslipData[0]?.settlement}
                      </Typography>
                    </Grid>

                    <Grid xs={6} style={{ padding: '7px', textAlign: 'start' }}>
                      <Typography variant="" style={{ fontSize: '16px' }}>
                        Net Amount
                      </Typography>
                    </Grid>
                    <Grid xs={6} style={{ padding: '7px', textAlign: 'end' }}>
                      <Typography
                        variant=""
                        style={{ fontSize: '20px', fontWeight: 600 }}
                      >
                        ₹{' '}
                        {payslipData[0]?.net_amount ||
                          payslipData[0]?.net_salary}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </Card>
            )}

            {userData.role == 'admin' ? (
              payslipData[0]?.payment_status == '0' ? (
                <>
                  {!payslipData[0]?.current_month && (
                    <Button
                      variant="contained"
                      style={{
                        marginTop: '10px',
                        width: '100%',
                        color: '#ffffff',
                        backgroundColor: '#21CD12',
                      }}
                      onClick={handlePayNow}
                    >
                      Pay Now
                    </Button>
                  )}

                  <Grid container className="mt-3 p-0">
                    <Grid xs={6}>
                      <Typography style={{ fontWeight: 600, fontSize: '20px' }}>
                        Remark
                      </Typography>
                    </Grid>
                    <Grid xs={6} textAlign="end">
                      <Button
                        variant="contained"
                        style={{
                          padding: '5px 30px',
                          color: '#ffffff',
                          backgroundColor: '#21CD12',
                        }}
                        onClick={handleShow}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </div>

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton style={{ backgroundColor: '#dbe9f7' }}>
              <Modal.Title>Add Remark</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#f8f9fa' }}>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formDate">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="remark_date"
                    value={formData.remark_date}
                    onChange={handleChange}
                    max={getMaxDate()}
                    isInvalid={!!formErrors.remark_date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.remark_date}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formStatus">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    isInvalid={!!formErrors.category}
                  >
                    <option value="">Select</option>
                    <option value="1">Appreciation</option>
                    <option value="2">Disappointed</option>
                    <option value="3">Escalation</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formErrors.category}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                    rows={3}
                    isInvalid={!!formErrors.remark}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.remark}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      marginTop: '10px',
                      padding: '5px 30px',
                      color: '#ffffff',
                      backgroundColor: '#21CD12',
                    }}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
          {userData.role == 'admin' ? (
            payslipData[0]?.payment_status == '1' ? (
              <Button
                variant="contained"
                style={{
                  marginTop: '10px',
                  width: '100%',
                  color: '#ffffff',
                  backgroundColor: '#0D3965',
                }}
              >
                Remark
              </Button>
            ) : (
              ''
            )
          ) : (
            <Button
              variant="contained"
              style={{
                marginTop: '10px',
                width: '100%',
                color: '#ffffff',
                backgroundColor: '#0D3965',
              }}
            >
              Remark
            </Button>
          )}
          <div className="remark_data">
            {performance
              ? performance.map((perform, item) => (
                  <Card
                    style={{
                      marginTop: '10px',
                      borderRadius: '10px',
                      maxHeight: '91.4px',
                    }}
                  >
                    <Grid container key={item}>
                      <Grid
                        xs={4}
                        style={{ maxWidth: '35%', flexBasis: '35%' }}
                      >
                        <Box
                          style={{
                            backgroundColor: '#0D3965',
                            padding: '10px 8px',
                            borderRadius: '10px 0 0 10px',
                            maxHeight: '91.4px',
                          }}
                        >
                          <Typography
                            style={{
                              color: '#ffffff',
                              fontSize: '13px',
                              marginBottom: '10px',
                            }}
                          >
                            <CalendarMonthIcon
                              style={{
                                width: '15px',
                                height: '15px',
                                marginRight: '5px',
                              }}
                            />
                            {formatDateFromAPI(perform.remark_date)}
                          </Typography>
                          <Typography
                            style={{
                              color: 'white',
                              fontSize: '12px',
                            }}
                          >
                            {perform.category === '1' ? (
                              <>
                                <span className="w-100 d-flex">
                                  <ThumbUpAltIcon
                                    style={{
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '5px',
                                    }}
                                  />
                                  <h6 style={{ fontSize: '12px' }}>
                                    Appreciation
                                  </h6>
                                </span>
                              </>
                            ) : perform.category === '2' ? (
                              <>
                                <span className="w-100 d-flex">
                                  <ThumbDownAltIcon
                                    style={{
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '5px',
                                    }}
                                  />
                                  <h6 style={{ fontSize: '12px' }}>
                                    Disappointed
                                  </h6>
                                </span>
                              </>
                            ) : perform.category === '3' ? (
                              <>
                                <span className="w-100 d-flex">
                                  <EscalatorWarningIcon
                                    style={{
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '5px',
                                    }}
                                  />
                                  <h6 style={{ fontSize: '12px' }}>
                                    Escalation
                                  </h6>
                                </span>
                              </>
                            ) : (
                              '0'
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={7}>
                        <Box style={{ padding: '20px' }}>
                          <Typography
                            style={{
                              color: '#000000',
                              fontWeight: 600,
                              fontSize: '13px',
                            }}
                          >
                            {perform.remark}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))
              : ''}
          </div>
        </Container>
      </section>
    </div>
  )
}

export default Payslip
