import React, { useState, useEffect } from 'react'
import './attendence.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import backendApi from '../helper.js'
import Loader from '../js/loader.js'
import Inout_List from './ListAPI.js'
import Toastinette from '../js/toastinette.js'
import { AUTOCLOSE, POSITION, PROGRESS } from '../constant.js'
import logo from '../img/birthday.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import confetti from 'canvas-confetti'

const Attendence = () => {
  const [currentDateTime1, setCurrentDateTime] = useState(new Date())
  const [isBirthday, setIsBirthday] = useState(false)
  const [birthdayPerson, setBirthdayPerson] = useState([])
  const [holidays, setHolidays] = useState([])
  const [userBioLog, setUserBioLog] = useState({})
  const [time, setTime] = useState(new Date())
  const [isTimingActive, setIsTimingActive] = useState(true)
  const hrms_users_id = JSON.parse(localStorage.getItem('user_Data'))
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isTimingActive) {
        setTime(new Date())
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [isTimingActive])

  function formatDate(dateTime) {
    const day = dateTime.getDate().toString().padStart(2, '0')
    const month = dateTime.toLocaleString('en-US', { month: 'short' })
    const year = dateTime.getFullYear()

    return `${day} ${month} ${year}`
  }
  const formattedDate = formatDate(currentDateTime1)
  const [clockTime, setClockTime] = useState(
    new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    })
  )
  useEffect(() => {
    const intervalId = setInterval(() => {
      setClockTime(
        new Date().toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        })
      )
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    fetchUserLog()
  }, [])

  function makeItRain() {
    var end = Date.now() + 2 * 1000
    var colors = ['#bb0000', '#ffffff']

    ;(function frame() {
      confetti({
        particleCount: 6,
        angle: 80,
        spread: 70,
        origin: { x: 0, y: 0.6 },
        // colors: colors,
      })
      confetti({
        particleCount: 6,
        angle: 100,
        spread: 70,
        origin: { x: 1, y: 0.6 },
        // colors: colors,
      })

      if (Date.now() < end) {
        requestAnimationFrame(frame)
      }
    })()
  }

  const fetchUserLog = async () => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'select_all_biodata_user_id',
        id: hrms_user_id.id,
        limit: '10',
      }

      await backendApi(data, hrms_user_id.user_auth)
        .then((result) => {
          console.log('result', result)
          setUserBioLog(result)
          setBirthdayPerson(result.birthday_person)
          setIsBirthday(result.birthday)
          if (result.birthday && !localStorage.getItem('birthday')) {
            makeItRain()
            localStorage.setItem('birthday', result.birthday)
          }
          setHolidays(result.holidays)
          Loader.close()
          setLoader(false)
          window.reload()
        })
        .catch(() => {
          Loader.close()
          setLoader(false)
          console.log('error')
        })
    }
  }

  function formatdate(data) {
    let d = new Date(data)
    if (isNaN(d.getTime())) {
      console.error('Invalid date:', data)
      return null
    }

    let day = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(d)
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

    return `${da} ${mo} ${ye}`
  }

  function formattime(data) {
    if (typeof data !== 'string') {
      return null
    }
    var timeParts = data.split(':')

    if (timeParts.length < 2) {
      return null
    }

    var hours = parseInt(timeParts[0], 10)
    var minutes = parseInt(timeParts[1], 10)

    if (isNaN(hours) || isNaN(minutes)) {
      return null
    }

    var d = new Date()
    d.setHours(hours)
    d.setMinutes(minutes)

    return d.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  let timingInterval
  const handleClick2 = () => {
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    const data = { action: 'update_data_user_id', id: hrms_user_id.id }
    backendApi(data, hrms_user_id.user_auth)
      .then((result) => {
        if (result.error === false) {
          fetchUserLog()
          Toastinette.init({
            position: POSITION,
            title: 'Congratulations',
            message: result.msg,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
        } else {
          Toastinette.init({
            position: POSITION,
            title: 'Oops...',
            message: result.msg,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
        }
      })
      .catch((error) => {
        console.log('Error: ', error)
      })
  }

  const handleClick = () => {
    Loader.open()
    const hrmsUserId = JSON.parse(localStorage.getItem('user_Data'))
    const data = { action: 'insert_data_user_id', id: hrmsUserId.id }

    backendApi(data, hrmsUserId.user_auth)
      .then((result) => {
        if (!result.error) {
          fetchUserLog()
          clearInterval(timingInterval)
          Loader.close()
          Toastinette.init({
            position: POSITION,
            title: 'Congratulations',
            message: result.msg,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
        } else {
          Toastinette.init({
            position: POSITION,
            title: 'Oops...',
            message: result.msg,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
        }
      })
      .catch((error) => {
        console.log('Error: ', error)
      })
      .finally(() => {
        setIsTimingActive(false)
      })
    timingInterval = setInterval(() => {}, 1000)
  }

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  function format_Date(date) {
    const eventDate = new Date(date)
    const day = eventDate.getDate()

    let suffix = 'th'
    if (day === 1) {
      suffix = 'st'
    } else if (day === 2) {
      suffix = 'nd'
    } else if (day === 3) {
      suffix = 'rd'
    }

    return (
      new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'long',
      }).format(eventDate) + suffix
    )
  }

  return (
    <>
      {!loader && (
        <section className="attendance-page hrms_content">
          <section className="punch-section">
            <div className="container">
              <div className="row">
                <h3 className="head-title font-weight-bold mb-2 p-0">
                  Hello, {hrms_users_id.username}
                </h3>
                <div id="punch-in-out" className="col-lg-12 row p-0">
                  <div class="col-lg-5">
                    {userBioLog?.checkintime != 0 ? (
                      <>
                        <p class="punch-in">
                          In time
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43726 15.628 1.87979 13.4881 2.02167 11.3363C2.16356 9.18455 2.9972 7.13631 4.39828 5.49706C5.79935 3.85781 7.69278 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                stroke="#19DA4F"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M22 4L12 14.01L9 11.01"
                                stroke="#19DA4F"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </p>
                        <h5 class="punchin-time ">
                          {formattime(userBioLog?.checkintime)}
                        </h5>
                      </>
                    ) : (
                      <>
                        <p class="punch-in">In time</p>
                        <h5 class="punchin-time ">
                          <span
                            class="clock-time"
                            style={{ width: 'max-content' }}
                          >
                            {clockTime.slice(0, -3)} {clockTime.slice(-2)}
                          </span>
                        </h5>
                      </>
                    )}

                    <p class="punchin-date">{formatdate(userBioLog?.today)}</p>
                    {userBioLog?.checkintime == 0 ? (
                      <button
                        class="punchout-btn"
                        id="mark_in"
                        onClick={handleClick}
                      >
                        Punch in
                      </button>
                    ) : (
                      <button class="checkin-btn">Check in</button>
                    )}
                  </div>
                  <div
                    className={
                      userBioLog?.checkintime == 0
                        ? 'col-lg-5 punchin_first d-cursor'
                        : 'col-lg-5'
                    }
                  >
                    {userBioLog?.checkouttime != '00:00:00' &&
                    userBioLog?.checkouttime != 0 ? (
                      <>
                        <p class="punch-in">
                          Out time
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43726 15.628 1.87979 13.4881 2.02167 11.3363C2.16356 9.18455 2.9972 7.13631 4.39828 5.49706C5.79935 3.85781 7.69278 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                stroke="#19DA4F"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M22 4L12 14.01L9 11.01"
                                stroke="#19DA4F"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </p>
                        <h5 class="punchout-time ">
                          {formattime(userBioLog?.checkouttime)}
                        </h5>
                      </>
                    ) : (
                      <>
                        <p class="punch-in">Out time </p>
                        <h5 class="punchout-time ">
                          <span
                            class="clock-time"
                            style={{ width: 'max-content' }}
                          >
                            {clockTime.slice(0, -3)} {clockTime.slice(-2)}
                          </span>
                        </h5>
                      </>
                    )}
                    <p class="punchout-date">{formatdate(userBioLog?.today)}</p>
                    {userBioLog?.checkouttime != '00:00:00' &&
                    userBioLog?.checkouttime != 0 ? (
                      <button class="checkin-btn">Check out</button>
                    ) : (
                      <button
                        className={
                          userBioLog?.checkintime == 0
                            ? 'punchout-btn punchin_first d-cursor'
                            : 'punchout-btn'
                        }
                        id="mark_out"
                        onClick={handleClick2}
                      >
                        Punch out
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isBirthday && (
            <div
              className="birthdaywish ml-1"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#4934B6',
                fontSize: '23px',
                fontWeight: '900',
                fontFamily: 'Poota One',
                lineHeight: '33.3px',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.5)',
              }}
            >
              <span>
                Happy
                <br /> <div className="">Birthday</div>
              </span>

              <img src={logo} alt="no" className="images" />
            </div>
          )}
          {birthdayPerson.length > 0 ? (
            <div
              className="birthdaywish ml-1"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#4934B6',
                fontSize: '20px',
                fontWeight: '900',
                fontFamily: 'Poota One',
                lineHeight: '33.3px',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.5)',
              }}
            >
              <div
                className=""
                style={{
                  backgroundColor: '#FFFFFF9E',
                  borderRadius: '20px',
                  width: '250px',
                  padding: '8px',
                }}
              >
                <span style={{ color: '#000000' }}>
                  Today is{' '}
                  <span style={{ color: '#4934B6' }}>
                    {birthdayPerson.length > 1
                      ? birthdayPerson.join(', ')
                      : birthdayPerson[0]}
                  </span>{' '}
                  birthday{birthdayPerson.length > 1 ? 's' : ''} wish{' '}
                  {birthdayPerson.length > 1 ? 'them' : 'her'} a
                </span>
                <span style={{ color: '#4934B6' }}> Happy Birthday</span>
              </div>

              <img
                src={logo}
                alt="no"
                className="imageswish"
                style={{ borderRadius: '8px', width: '100%', height: '100%' }}
              />
            </div>
          ) : (
            ''
          )}
          <div className="slider-container">
            {holidays != null ? (
              holidays.length > 1 ? (
                <Slider {...settings}>
                  {holidays.map((event, index) => {
                    const holidayDate = new Date(event.date)
                    const today = new Date()
                    const tomorrow = new Date(today)
                    tomorrow.setDate(today.getDate() + 1)
                    let displayDate
                    if (holidayDate.toDateString() === today.toDateString()) {
                      displayDate = 'today'
                    } else if (
                      holidayDate.toDateString() === tomorrow.toDateString()
                    ) {
                      displayDate = 'tomorrow'
                    }
                    const holidayStatus =
                      event.type == 1
                        ? 'holiday'
                        : event.type == 2
                        ? 'half-day'
                        : ''
                    return (
                      <div
                        className="holiday ml-4"
                        style={{
                          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.5)',
                          margin: '12px 0px',
                        }}
                        key={index}
                      >
                        <div
                          className="holiday1"
                          style={{
                            backgroundColor: '#5450D6',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#fff',
                            tabSize: '20px',
                            lineHeight: '22.5',
                            borderRadius: '10px 10px 0px 0px',
                          }}
                        >
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              lineHeight: '22.5',
                              fontWeight: '600',
                              fontSize: '16px',
                            }}
                          >
                            <path
                              d="M26.9167 5.66669H7.08333C5.51853 5.66669 4.25 6.93521 4.25 8.50002V28.3334C4.25 29.8982 5.51853 31.1667 7.08333 31.1667H26.9167C28.4815 31.1667 29.75 29.8982 29.75 28.3334V8.50002C29.75 6.93521 28.4815 5.66669 26.9167 5.66669Z"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.6667 2.83331V8.49998"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.3333 2.83331V8.49998"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.25 11.1666H29.75"
                              stroke="white"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <text
                              x="50%"
                              y="75%"
                              textAnchor="middle"
                              fill="white"
                              fontSize="13"
                              fontWeight="bold"
                            >
                              {holidayDate.toLocaleString('default', {
                                day: 'numeric',
                              })}
                            </text>
                          </svg>
                          <span
                            style={{
                              fontSize: '20px',
                              lineHeight: '30px',
                              fontWeight: '600',
                            }}
                          >
                            {' '}
                            {holidayDate.toLocaleString('default', {
                              month: 'long',
                            })}
                          </span>
                          <div className="back"></div>
                          <div className="back1"></div>
                        </div>
                        <div
                          style={{
                            color: '#333333',
                            textAlign: 'center',
                            display: 'flex',
                            // alignItems: 'center',
                            fontWeight: '600',
                            fontSize: '20px',
                            fontStyle: 'italic',
                            padding: '20px 15px',
                            height: '135px',
                          }}
                        >
                          <span>
                            {' '}
                            {displayDate === 'today'
                              ? 'Today'
                              : displayDate === 'tomorrow'
                              ? 'Tomorrow'
                              : ''}{' '}
                            {format_Date(event.date)}, is a {holidayStatus}{' '}
                            occasion of
                            <span
                              style={{
                                fontWeight: '900',
                                color: '#000000',
                                fontSize: '22px',
                              }}
                            >
                              {' '}
                              {event.title}
                            </span>
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              ) : (
                <div
                  className="holiday ml-4"
                  style={{
                    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.5)',
                    margin: '12px',
                  }}
                >
                  <div
                    className="holiday1"
                    style={{
                      backgroundColor: '#5450D6',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#fff',
                      tabSize: '20px',
                      lineHeight: '22.5',
                      borderRadius: '10px 10px 0px 0px',
                    }}
                  >
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        lineHeight: '22.5',
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                    >
                      <path
                        d="M26.9167 5.66669H7.08333C5.51853 5.66669 4.25 6.93521 4.25 8.50002V28.3334C4.25 29.8982 5.51853 31.1667 7.08333 31.1667H26.9167C28.4815 31.1667 29.75 29.8982 29.75 28.3334V8.50002C29.75 6.93521 28.4815 5.66669 26.9167 5.66669Z"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.6667 2.83331V8.49998"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.3333 2.83331V8.49998"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />

                      <text
                        x="50%"
                        y="75%"
                        textAnchor="middle"
                        fill="white"
                        fontSize="13"
                        fontWeight="bold"
                      >
                        {new Date(holidays[0]?.date).toLocaleString('default', {
                          day: 'numeric',
                        })}
                      </text>
                    </svg>
                    <span
                      style={{
                        fontSize: '20px',
                        lineHeight: '30px',
                        fontWeight: '600',
                      }}
                    >
                      {' '}
                      {new Date(holidays[0]?.date).toLocaleString('default', {
                        month: 'long',
                      })}
                    </span>
                    <div className="back"></div>
                    <div className="back1"></div>
                  </div>
                  <div
                    style={{
                      color: '#333333',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: '600',
                      fontSize: '20px',
                      fontStyle: 'italic',
                      padding: '20px 15px',
                    }}
                  >
                    <span>
                      {' '}
                      {/* Tommorow {format_Date(holidays[0]?.date)}, is a holiday
                  occasion of */}
                      {(() => {
                        const today = new Date()
                        const holidayDate = new Date(holidays[0]?.date)
                        const tomorrow = new Date(today)
                        tomorrow.setDate(today.getDate() + 1)

                        if (
                          holidayDate.getDate() === today.getDate() &&
                          holidayDate.getMonth() === today.getMonth() &&
                          holidayDate.getFullYear() === today.getFullYear()
                        ) {
                          return `Today ${format_Date(
                            holidays[0]?.date
                          )}, is a holiday occasion of`
                        } else if (
                          holidayDate.getDate() === tomorrow.getDate() &&
                          holidayDate.getMonth() === tomorrow.getMonth() &&
                          holidayDate.getFullYear() === tomorrow.getFullYear()
                        ) {
                          return `Tomorrow ${format_Date(
                            holidays[0]?.date
                          )}, is a holiday occasion of`
                        }
                      })()}
                      <span
                        style={{
                          fontWeight: '900',
                          color: '#000000',
                          fontSize: '22px',
                        }}
                      >
                        {' '}
                        {holidays[0]?.title}
                      </span>
                    </span>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>

          <section className="history">
            <div className="container">
              <div className="history-heading p-0">
                <h2
                  className="hishead-title px-0"
                  style={{ fontWeight: 'bold', fontSize: '24px' }}
                >
                  History
                </h2>
                {hrms_users_id.role != 'admin' ? (
                  <a
                    href="#/leave"
                    className="leave-page-link"
                    style={{ color: '#B3B0ED' }}
                  >
                    Leaves
                  </a>
                ) : (
                  ''
                )}
              </div>
              <div className="row history-cont attnd-his justify-content-between">
                <Inout_List loaddata={userBioLog} fetchUserLog={fetchUserLog} />
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  )
}

export default Attendence
