import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import backendApi from '../../helper'
import { useNavigate } from 'react-router-dom'
import { RiMoneyRupeeCircleLine } from 'react-icons/ri'

const Notifications = () => {
  const [notifications, setNotifications] = useState([])
  const [userRole, setUserRole] = useState('')
  const navigate = useNavigate() // Define navigate properly using the const keyword

  useEffect(() => {
    fetchNotifications()
    // Fetch user role when component mounts
    const userData = JSON.parse(localStorage.getItem('user_Data'))
    if (userData) {
      setUserRole(userData.role)
    }
  }, [])

  const fetchNotifications = async () => {
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'fetch_noti',
        serialnumber: hrms_user_id.serialnumber,
      }
      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        if (result.error === false) {
          setNotifications(result.notification)
        } else {
          console.log('No notifications found')
        }
      } catch (error) {
        console.log('Error fetching notification data:', error)
      }
    }
  }

  const handleNotifStatus = async (notificationId, notitype) => {
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    const data = {
      action: 'change_status',
      notiid: notificationId,
      serialnumber: hrms_user_id.serialnumber,
    }
    try {
      const result = await backendApi(data, hrms_user_id.user_auth)

      if (userRole === 'admin') {
        if (notitype == 'apply_leave') {
          navigate('/listing-page')
        } else if (notitype == 'apply_time') {
          navigate('/requests')
        } else if (notitype == 'apply_reimb') {
          navigate('/reimberssment')
        }
      }
      if (userRole === 'staff') {
        if (notitype == 'approve_leave' || notitype == 'reject_leave') {
          navigate('/leave')
        } else if (notitype == 'apply_time' || notitype == 'reject_time') {
          navigate('/attendance')
        } else if (notitype == 'apply_reimb' || notitype == 'reject_reimb') {
          navigate('/reimberssment')
        }
      }
      // handle result if needed
    } catch (error) {
      console.log('Error changing notification status:', error)
    }
  }

  const formatDate = (dateString) => {
    const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' }
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true }
    const date = new Date(dateString)

    const formattedDate = date.toLocaleDateString('en-GB', dateOptions)
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions)

    return `${formattedDate}, ${formattedTime}`
  }

  return (
    <div className="hrms_content">
      {/* Render notification count */}

      {notifications && notifications.length > 0 ? (
        notifications.map((notification, index) => (
          <Box
            key={index}
            style={{ backgroundColor: '#ffffff', marginTop: '5px' }}
            onClick={() =>
              handleNotifStatus(notification.id, notification.notitype)
            }
          >
            <Grid container style={{ padding: '20px', alignItems: 'center' }}>
              <Grid xs={2}>
                {notification.notitype == 'apply_leave' ||
                notification.notitype == 'approve_leave' ||
                notification.notitype == 'reject_leave' ? (
                  <CalendarTodayIcon
                    style={{
                      color:
                        notification.notitype == 'apply_leave'
                          ? 'blue'
                          : notification.notitype == 'approve_leave'
                          ? 'green'
                          : 'red',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                ) : notification.notitype == 'apply_time' ||
                  notification.notitype == 'approve_time' ||
                  notification.notitype == 'reject_time' ? (
                  <AccessTimeOutlinedIcon
                    style={{
                      color:
                        notification.notitype == 'approve_time'
                          ? 'green'
                          : notification.notitype == 'apply_time'
                          ? 'blue'
                          : 'red',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                ) : notification.notitype == 'apply_reimb' ||
                  notification.notitype == 'approve_reimb' ||
                  notification.notitype == 'reject_reimb' ? (
                  <CurrencyRupeeOutlinedIcon
                    style={{
                      color:
                        notification.notitype == 'apply_reimb'
                          ? 'blue'
                          : notification.notitype == 'approve_reimb'
                          ? 'green'
                          : 'red',
                      width: '40px',
                      height: '40px',
                    }}
                  />
                ) : (
                  ''
                )}
              </Grid>
              <Grid xs={10}>
                {' '}
                <Typography
                  style={{
                    fontWeight: notification.status == '0' ? 700 : 'normal',
                    fontSize: '18px',
                  }}
                >
                  {notification.noticontent.charAt(0).toUpperCase() +
                    notification.noticontent.slice(1)}
                </Typography>
                <Typography style={{ fontSize: '12px' }}>
                  <span>{formatDate(notification.created_at)}</span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))
      ) : (
        <Typography variant="h5" align="center">
          No notifications
        </Typography>
      )}
    </div>
  )
}

export default Notifications
