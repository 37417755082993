import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/custom.css'
import '../../css/style.css'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import backendApi from '../../helper'
import Loader from '../../js/loader'
import { Typography } from '@mui/material'
import { IoPersonCircleOutline } from 'react-icons/io5'

const History = () => {
  const [monthData, setMonthData] = useState([])
  const [totalSalary, setTotalSalary] = useState()
  const [leave, setLeaveData] = useState()
  const navigate = useNavigate()
  const location = useLocation()
  const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
  const queryParams = new URLSearchParams(location.search)
  var serialnumber = queryParams.get('serialnumber')
  serialnumber = serialnumber.replace(/ /g, '+')

  useEffect(() => {
    console.log('serialnumber:', serialnumber) // Debug log

    const fetchMonthlyReport = async () => {
      if (!serialnumber) {
        console.log('No serial number provided')
        return
      }

      Loader.open()
      const data = {
        action: 'fetch_monthly_report',
        serialnumber,
      }

      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        Loader.close()
        if (!result.error) {
          setMonthData(result.userMonthlyData)
          setTotalSalary(result.total_salary)
          setLeaveData(result.approved_leave_data)
        } else {
          console.log('Failed to fetch monthly report:', result.message)
        }
      } catch (error) {
        Loader.close()
        console.log('Error during API call:', error)
      }
    }

    fetchMonthlyReport()
  }, [serialnumber])

  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    return months[monthNumber - 1] // Adjust index since arrays are zero-based
  }
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()

  return (
    <>
      <Col lg={12}>
        <Typography
          style={{
            fontWeight: 600,
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IoPersonCircleOutline style={{ height: '27px', width: '27px' }} />{' '}
          {monthData[0]?.username}
        </Typography>

        <Col lg={12} className="p-0 d-flex justify-content-between">
          <Col lg={5} className="history-block" style={{ marginRight: '7px' }}>
            <p className="m-0" style={{ fontSize: '12px' }}>
              Total Salary : ₹ {totalSalary}
            </p>
          </Col>
          <Col lg={5} className="history-block" style={{ marginLeft: '7px' }}>
            <p className="m-0" style={{ fontSize: '12px' }}>
              Pending SD : ₹10,000
            </p>
          </Col>
        </Col>

        <Col lg={12} className="p-0 d-flex justify-content-between">
          <Col lg={5} className="history-block" style={{ marginRight: '7px' }}>
            <p className="m-0" style={{ fontSize: '12px' }}>
              Total Leaves : {leave > 1 ? `${leave} days` : `${leave} day`}
            </p>
          </Col>
          <Col lg={5} className="history-block" style={{ marginLeft: '7px' }}>
            <p className="m-0" style={{ fontSize: '12px' }}>
              Total Attend : {monthData[0]?.days_present}
            </p>
          </Col>
        </Col>
      </Col>
      <Container
        className="month_report_list"
        fluid
        style={{ overflow: 'auto', height: '630px' }}
      >
        <Row>
          <Col lg={12} className="p-0">
            {monthData && monthData.length > 0 ? (
              monthData.map((data, index) => (
                <div className="row leave-item" key={index}>
                  <div className="col-sm-12 d-flex justify-content-between profile-row align-items-center">
                    <div className="history-left d-flex align-items-center">
                      <div className="history-icon">
                        {data.no_of_days_in_month}
                      </div>
                      <div className="history-details">
                        <h4 className="font-weight-bold mb-1">
                          {getMonthName(data.month)} {data.year}
                        </h4>
                        <p className="historySubTitle m-0">
                          Salary :{' '}
                          <span style={{ color: '#21CD12' }}>
                            ₹{data.total_salary}
                          </span>
                        </p>
                        <p className="historySubTitle m-0">
                          Deduct SD :{' '}
                          <span style={{ color: '#F92D07' }}>
                            -₹{data.deduction}
                          </span>
                        </p>
                        <p className="historySubTitle m-0">
                          Released SD ({data.releasedSdMonth}) :{' '}
                          <span style={{ color: '#21CD12' }}>
                            +₹{data.settlement}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="history-right">
                      {data.month == currentMonth &&
                      data.year == currentYear ? (
                        <></>
                      ) : (
                        <Col lg={12} className="right-top">
                          <a
                            style={{
                              color: '#1976D2',
                              textDecoration: 'underline',
                            }}
                            onClick={() =>
                              navigate(
                                `/performanceReport?serialnumber=${serialnumber}&month=${data.month}&year=${data.year}`
                              )
                            }
                          >
                            View Report
                          </a>
                        </Col>
                      )}

                      <Col lg={12} className="right-bottom">
                        <span
                          className="right-icon"
                          onClick={() =>
                            navigate(
                              `/monthreport?serialnumber=${serialnumber}&Month=${data.month}&year=${data.year}`
                            )
                          }
                        >
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16 2V6"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8 2V6"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3 10H21"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span
                          className="right-icon"
                          onClick={() =>
                            navigate(
                              `/payslip?serialnumber=${serialnumber}&Month=${data.month}&year=${data.year}`
                            )
                          }
                        >
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 12 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.786 3.66V4.017H11.495V5.403H8.681C8.555 6.369 8.275 7.125 7.841 7.671C7.421 8.217 6.805 8.609 5.993 8.847C5.195 9.085 4.131 9.204 2.801 9.204V10.863C2.801 12.753 3.627 13.698 5.279 13.698C6.903 13.698 7.715 12.788 7.715 10.968H9.479C9.465 12.368 9.094 13.425 8.366 14.139C7.638 14.853 6.602 15.21 5.258 15.21C3.872 15.21 2.815 14.825 2.087 14.055C1.373 13.285 1.016 12.207 1.016 10.821V7.86C2.612 7.86 3.788 7.811 4.544 7.713C5.314 7.601 5.874 7.377 6.224 7.041C6.574 6.691 6.798 6.145 6.896 5.403H0.0710001V4.017H6.98V3.639V1.749H0.0710001V0.342H11.495V1.749H8.786V3.66Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span
                          className="right-icon"
                          onClick={() =>
                            navigate(
                              `/performance?serialnumber=${serialnumber}&Month=${data.month}&year=${data.year}`
                            )
                          }
                        >
                          <svg
                            className="feather feather-activity"
                            fill="none"
                            height="15"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            width="15"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
                          </svg>
                        </span>
                      </Col>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h4>No data found</h4>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default History
