import { Padding } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning'
import '../../css/custom.css'
import Loader from '../../js/loader'
import backendApi from '../../helper'
import { useLocation } from 'react-router-dom'
const PerformanceReport = () => {
  const [report, setReports] = useState([])
  const [leave, setLeave] = useState([])
  const [remark, setRemark] = useState([])
  const location = useLocation()
  const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
  const queryParams = new URLSearchParams(location.search)
  var serialnumber = queryParams.get('serialnumber')
  serialnumber = serialnumber.replace(/ /g, '+')
  const month = queryParams.get('month')
  const year = queryParams.get('year')

  const fetchPerformanceReport = async () => {
    if (!serialnumber) {
      console.log('Serial number, month, or year not provided')
      return
    }

    Loader.open()
    const data = {
      action: 'fetch_staff_monthly_report',
      serialnumber,
      month,
      year,
    }

    try {
      const result = await backendApi(data, hrms_user_id.user_auth)
      Loader.close()
      if (!result.error) {
        setReports(result?.payroll_data)
        setRemark(result?.remark_data)
        setLeave(result?.approved_leave_data)
      } else {
        console.log('Failed to fetch month-wise data:', result.message)
      }
    } catch (error) {
      Loader.close()
      console.log('Error during API call:', error)
    }
  }

  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return months[monthNumber - 1]
  }

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetchPerformanceReport()
  }, [])

  function formatDateFromAPI(apiDateString) {
    const dateObject = new Date(apiDateString)
    if (!(dateObject instanceof Date) || isNaN(dateObject)) {
      return 'Invalid Date'
    }
    const day = dateObject.getDate()
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
      dateObject
    )
    const year = dateObject.getFullYear()
    return `${day} ${month} ${year}`
  }

  return (
    <div>
      <Container className="pb-1 hrms_content">
        <Typography
          style={{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 700,
            color: '#0D3965',
          }}
        >
          EMPLOYEE PERFORMANCE REPORT
        </Typography>
        <Grid container style={{ margin: 'auto' }}>
          <Grid
            style={{ color: '#1C79D3', fontSize: '10px', fontWeight: 600 }}
            xs={6}
          >
            Empoyee Id:{report[0]?.serialnumber}
          </Grid>
          <Grid
            style={{
              color: '#1C79D3',
              fontSize: '10px',
              fontWeight: 600,
              textAlign: 'end',
            }}
            xs={6}
          >
            {getMonthName(report[0]?.month)} {report[0]?.year}
          </Grid>
        </Grid>
        <Box
          style={{
            backgroundColor: '#0D3965',
            color: '#ffffff',
            padding: '7px 0px',
            marginTop: '7px',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            marginBottom: '12px',
          }}
        >
          <Typography
            style={{ fontSize: '12px', fontWeight: 700, textAlign: 'center' }}
          >
            NAME : {report[0]?.username}
          </Typography>
        </Box>
        <Grid container style={{ margin: 'auto' }}>
          <Grid xs={6} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Full Days
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.full_days}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Gross Salary
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  ₹ {report[0]?.total_salary}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Half Days
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.half_days}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Deduction
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.deduction}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  No of days Absent
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.days_absent}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Settlement Amt.
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.settlement}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Leaves Approved
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {leave}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Security Deposit
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.security_deposit}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Late Entries
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.late_entry}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Incentive
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.incentive}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Total Hours
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.total_hours}/{report[0]?.total_working_days}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid xs={6} style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
            <Card
              style={{
                padding: '5px 15px',
                height: '45px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container style={{ margin: 'auto' }}>
                <Grid
                  xs={8}
                  style={{
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  Net Pay
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    textAlign: 'end',
                    color: '#000000',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {report[0]?.net_salary || report[0]?.net_amount}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Box
          style={{
            backgroundColor: '#0D3965',
            color: '#ffffff',
            padding: '7px 0px',
            marginTop: '7px',
            margin: '12px 0px',
          }}
        >
          <Typography
            style={{ fontSize: '12px', fontWeight: 700, textAlign: 'center' }}
          >
            REMARK
          </Typography>
        </Box>
        {remark ? (
          remark.map((remarks, index) => (
            <Card key={index}>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    style={{
                      backgroundColor: '#0D3965',
                      color: '#ffffff',
                      padding: '7px',
                    }}
                  >
                    <Typography>
                      <CalendarMonthIcon
                        style={{ width: '15px', height: '15px' }}
                      />{' '}
                      <span style={{ fontSize: '10px' }}>
                        {formatDateFromAPI(remarks.remark_date)}
                      </span>
                    </Typography>
                    <Typography>
                      <span style={{ fontSize: '10px' }}>
                        {remarks.category === '1' ? (
                          <>
                            <span>
                              <ThumbUpAltIcon
                                style={{ width: '15px', height: '15px' }}
                              />
                              Appreciation
                            </span>
                          </>
                        ) : remarks.category === '2' ? (
                          <>
                            <span>
                              <ThumbDownAltIcon
                                style={{ width: '15px', height: '15px' }}
                              />{' '}
                              Disappointed
                            </span>
                          </>
                        ) : remarks.category === '3' ? (
                          <>
                            <span>
                              <EscalatorWarningIcon
                                style={{ width: '15px', height: '15px' }}
                              />
                              Escalation
                            </span>
                          </>
                        ) : (
                          '0'
                        )}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={8} style={{ padding: '7px' }}>
                  <Typography style={{ fontSize: '10px', fontWeight: 600 }}>
                    {remarks.remark}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ))
        ) : (
          <>
            <h6 className="text-center">No remark added</h6>
          </>
        )}
      </Container>
    </div>
  )
}

export default PerformanceReport
