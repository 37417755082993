import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import './App.css'
import './css/loder.css'
import './css/toastinette.min.css'

import Apply_leave from './Pages/Apply_leave'
import Admin_leave from './Pages/Admin_leave'
import Leave from './Pages/leave'
import Profiles from './Pages/MonthlyReport/Profiles'
import Navbar from './Pages/Navbar'
import History from './Pages/MonthlyReport/History'
import Month_Att from './Pages/MonthlyReport/Monthatt'
import Performance from './Pages/MonthlyReport/Performance'
import Add_perfor from './Pages/MonthlyReport/Add_perfor'
import Listing_page from './Pages/Att_operation/listing_page'
import Login from './Pages/login/login'
import Attendence from './attendence/attendence'
import Update from './attendence/update'
import Leave_Listing_Page from './Pages/Att_operation/Leave-Listing-Page'
import Requests from './Pages/Att_operation/requests'
import Dash_Index from './Pages/Dashboard/Index'
import Profiles2 from './Pages/MonthlyReport/Profiles2'
import Payslip from './Pages/MonthlyReport/Payslip'
import PerformanceReport from './Pages/MonthlyReport/PerformanceReport'
import Notifications from './Pages/Dashboard/Notifications'
import Reimberssment from './Pages/Reimberssment/Reimberssment'
import Footer from './Pages/footer'

function App() {
  // useEffect(() => {
  //   document.addEventListener('contextmenu', (event) => event.preventDefault())
  //   document.addEventListener('keydown', (event) => {
  //     console.log('event.key', event.key)
  //     if (
  //       event.key === 'F12' ||
  //       (event.ctrlKey && event.shiftKey && event.key === 'I') ||
  //       (event.ctrlKey && event.shiftKey && event.key === 'J') ||
  //       (event.ctrlKey && event.shiftKey && event.key === 'C') ||
  //       (event.ctrlKey && event.key === 'U')
  //     ) {
  //       event.preventDefault()
  //     }
  //   })
  // }, [])
  const location = useLocation()
  const navigate = useNavigate()
  const [notificationCount, setNotificationCount] = useState(0)

  useEffect(() => {
    const activeUser = JSON.parse(localStorage.getItem('user_Data'))
    const activeRoute = localStorage.getItem('lastRoute')
    if (!activeUser) {
      navigate('/login')
    } else if (activeUser && location.pathname === '/login') {
      if (activeUser.role === 'admin') {
        if (activeRoute) {
          navigate(activeRoute)
        } else {
          navigate('/Dashboard')
        }
      } else {
        if (activeRoute) {
          navigate(activeRoute)
        } else {
          navigate('/attendance-page')
        }
      }
    } else if (location.pathname === '/' && activeUser) {
      if (activeUser.role === 'admin') {
        if (activeRoute) {
          navigate(activeRoute)
        } else {
          navigate('/Dashboard')
        }
      } else {
        if (activeRoute) {
          navigate(activeRoute)
        } else {
          navigate('/attendance-page')
        }
      }
    }
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.setItem('lastRoute', location.pathname + location.search)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [location.pathname])

  useEffect(() => {
    localStorage.setItem('activeRoute', location.pathname)
  }, [location.pathname, location.search])

  return (
    <>
      <Navbar
        activeroute={location.pathname}
        notificationCount={notificationCount}
      />
      <Routes>
        <Route path="/Apply-leave" element={<Apply_leave />} />
        <Route path="/Admin-leave" element={<Admin_leave />} />
        <Route path="/Dashboard" element={<Dash_Index />} />
        <Route path="/leave" element={<Leave />} />
        <Route path="/profiles" element={<Profiles />} />
        <Route path="/profiles2" element={<Profiles2 />} />
        <Route path="/payslip" element={<Payslip />} />
        <Route path="/history" element={<History />} />
        <Route path="/monthreport" element={<Month_Att />} />
        <Route path="/reimberssment" element={<Reimberssment />} />
        <Route path="/performanceReport" element={<PerformanceReport />} />
        <Route path="/performance" element={<Performance />} />
        <Route path="/add-perfor" element={<Add_perfor />} />
        <Route path="/listing-page" element={<Leave_Listing_Page />} />
        <Route path="/login" element={<Login />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/attendance-page" element={<Attendence />} />
        <Route path="/update-page" element={<Update />} />
        <Route path="/notifications" element={<Notifications />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
