import React from 'react'
import Inout_List from './ListAPI'

const Update = () => {


  const formatTimeFromAPI = (time) => {
    if (!time) return ''; // Handle cases where time is undefined or null
    const [hours, minutes] = time.split(':');
    const formattedTime = new Date();
    formattedTime.setHours(hours);
    formattedTime.setMinutes(minutes);
    return formattedTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
  };

  return (
    <div>

      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">In Time</label>
        <input type="time" class="form-control" onChange={formatTimeFromAPI} id="exampleFormControlInput1" placeholder="Type here" />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Out Time</label>
        <input type="time" class="form-control" onChange={formatTimeFromAPI} id="exampleFormControlInput1" placeholder="Type here" />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Reason</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Enter reason"></textarea>
      </div>
      <div className=' d-flex align-items-center justify-content-center ' >
        <button type="button" class="btn btn-success   " style={{
          background: "#21CD12",
          borderRadius: "25px", width: "162px", border: "1px solid rgb(33, 205, 18)"
        }} >Submit</button> </div>
    </div>
  )
}

export default Update
