import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import backendApi from '../../helper.js'
import Inout_List from '../../attendence/ListAPI.js'
import Apply_leave from '../Apply_leave.js'
import { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/custom.css'
import '../../css/style.css'
import { useLocation, Link } from 'react-router-dom'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Container,
  Form,
  Modal,
  ModalBody,
  Tab,
  Tabs,
} from 'react-bootstrap'
import { Fade, Grid, Input, TextareaAutosize, Typography } from '@mui/material'
import Loader from '../../js/loader.js'
import { AUTOCLOSE, POSITION, PROGRESS } from '../../constant.js'
import Toastinette from '../../js/toastinette.js'

const Reimberssment = () => {
  const [pendingReimber, setPendingReimber] = useState([])
  const [reimber, setReimber] = useState([])
  const [rejectLeave, setRejectLeave] = useState([])
  const [accordionState, setAccordionState] = useState({})
  const [historyData, setHistoryData] = useState([])
  const [key, setKey] = useState('Pending')
  const [rejectReason, setRejectReason] = useState([])
  const [selectedLeaveId, setSelectedLeaveId] = useState(null)
  const [selectedSerialNo, setSelectedSerialNo] = useState(null)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [newAmount, setNewAmount] = useState('')
  const [newReason, setNewReason] = useState('')
  const [open, setOpen] = useState()
  const hrms_loggedin_user = JSON.parse(localStorage.getItem('user_Data'))
  const handleAddModalOpen = () => {
    setAddModalOpen(true)
  }

  const handleAddModalClose = () => {
    setAddModalOpen(false)
    setNewAmount('')
    setNewReason('')
  }

  const handleAddReimbursement = async () => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'add_reimbursement',
        serialnumber: hrms_user_id.serialnumber,
        amount: newAmount,
        reason: newReason,
      }
      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        if (result.error === false) {
          Toastinette.init({
            position: POSITION,
            title: 'Success',
            message: result.message,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
          handleAddModalClose()
          fechReimberData()
        } else {
          Toastinette.init({
            position: POSITION,
            title: 'Error',
            message: result.message,
            type: result.type,
            autoClose: AUTOCLOSE,
            progress: PROGRESS,
          })
          Loader.close()
          console.log('Failed to add reimbursement:', result.message)
        }
      } catch (error) {
        console.log('Error during API call:', error)
      }
    }
  }

  // <Link to="/apply-leave"></Link>
  const location = useLocation()
  const { leave_type, reason, from_date, to_date, day } = location.search

  useEffect(() => {
    fechReimberData()
  }, [])

  const fechReimberData = async () => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'view_reimbursement',
        serialnumber: hrms_user_id.serialnumber,
      }
      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        if (result.error === false) {
          if (result.Reimbursement) {
            setReimber(result.Reimbursement)
          } else {
            setPendingReimber(result.pending_reimbursements)
            setHistoryData(result.history_reimbursements)
          }
          Loader.close()
        } else {
          console.log('No reimber found')
          Loader.close()
        }
      } catch (error) {
        console.log('Error fetching reimber data:', error)
        Loader.close()
      }
    }
  }

  const handleApproved = async (leaveId, serialNo) => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    const data = {
      action: 'reimbursement_operation',
      serialnumber: serialNo,
      reimb_id: leaveId,
      reimb_status: '1',
      rejection_reason: '',
    }
    try {
      const result = await backendApi(data, hrms_user_id.user_auth)
      if (result.error === false) {
        Toastinette.init({
          position: POSITION,
          title: 'Congratulations',
          message: result.message,
          type: result.type,
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        Loader.close()
        fechReimberData()
      } else {
        Toastinette.init({
          position: POSITION,
          title: 'Oops...!',
          message: result.message,
          type: result.type,
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        Loader.close()
        console.log('Failed to approve reimbursement:', result.message)
      }
    } catch (error) {
      console.log('Error during API call:', error)
    }
  }

  const handleReject = async () => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    const data = {
      action: 'reimbursement_operation',
      serialnumber: selectedSerialNo,
      reimb_id: selectedLeaveId,
      reimb_status: '2',
      rejection_reason: rejectReason,
    }
    try {
      const result = await backendApi(data, hrms_user_id.user_auth)
      if (result.error === false) {
        Toastinette.init({
          position: POSITION,
          title: 'Reimbursement Rejected',
          message: result.message,
          type: result.type,
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        Loader.close()
        handleClose()
        fechReimberData()
      } else {
        Toastinette.init({
          position: POSITION,
          title: 'Oops...!',
          message: result.message,
          type: result.type,
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        Loader.close()
        console.log('Failed to reject reimbursement:', result.message)
      }
    } catch (error) {
      console.log('Error during API call:', error)
    }
  }

  const handleOpen = (leaveId, serialNo) => {
    setSelectedLeaveId(leaveId)
    setSelectedSerialNo(serialNo)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setRejectReason('')
    setSelectedLeaveId(null)
  }

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' }
    return new Date(dateString).toLocaleDateString('en-GB', options)
  }

  return (
    <>
      <section class="leaves-page hrms_content">
        <section class="leaves-list">
          <Container className="p-0">
            {hrms_loggedin_user.role != 'admin' ? (
              reimber.map((reimber, index) => (
                <div className="col-lg-12 row">
                  <div key={index} className="row leave-item">
                    <div className="col-sm-12 row history-row position-relative">
                      <Accordion className="accord accord-2">
                        <Accordion.Header>
                          <div className="leave-icon">
                            {reimber.status == '0' ? (
                              <div className="leave-processing">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 6V12L16 14"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            ) : reimber.status == '1' ? (
                              <div className="leave-approved">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M22 4L12 14.01L9 11.01"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            ) : (
                              <div className="leave-reject">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M15 9L9 15"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9 9L15 15"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                          <div className="leave-details">
                            <h5>{reimber.name}</h5>
                            <Typography
                              style={{
                                fontSize: '18px',
                                color: 'black',
                                fontWeight: 600,
                              }}
                            >
                              {reimber.username}
                            </Typography>
                            <Typography
                              className=""
                              style={{
                                color: 'black',
                                fontWeight: 500,
                              }}
                            >
                              <span>₹{reimber.amount}</span>{' '}
                            </Typography>
                            <Typography
                              className="date pending"
                              style={{ fontWeight: 500 }}
                            >
                              <span>{formatDate(reimber.created_at)}</span>{' '}
                            </Typography>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body isOpen={accordionState[reimber.id]}>
                          <div className="listing-body">
                            <div className="listing-desc">
                              <h5 className="lis-title">Reason</h5>
                              <h5 className="lis-content">{reimber.reason}</h5>
                            </div>
                            {reimber.rejection_reason && (
                              <div className="listing-desc">
                                <h5 className="lis-title">
                                  Reason of Rejection
                                </h5>
                                <h5 className="lis-content">
                                  {reimber.rejection_reason}
                                </h5>
                              </div>
                            )}
                            {hrms_loggedin_user.role == 'admin' && (
                              <div className="app-rejt mt-5 d-flex justify-content-between">
                                <button
                                  onClick={() =>
                                    handleApproved(
                                      reimber.id,
                                      reimber.serialnumber
                                    )
                                  }
                                  className="btn btn-approve"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    handleOpen(reimber.id, reimber.serialnumber)
                                  }
                                  className="btn btn-reject"
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-12 row">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-2 p-0"
                >
                  <Tab eventKey="Pending" title="Pending">
                    {pendingReimber.length > 0 ? (
                      pendingReimber.map(
                        (reimber, index) =>
                          reimber.status === '0' && (
                            <div key={index} className="row leave-item">
                              <div className="col-sm-12 row history-row position-relative">
                                <Accordion className="accord accord-2">
                                  <Accordion.Header>
                                    <div className="leave-icon">
                                      <div className="leave-processing">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M12 6V12L16 14"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                    <div className="leave-details">
                                      <h5>{reimber.name}</h5>
                                      <Typography
                                        style={{
                                          fontSize: '18px',
                                          color: 'black',
                                          fontWeight: 600,
                                        }}
                                      >
                                        {reimber.username}
                                      </Typography>
                                      <Typography
                                        className=""
                                        style={{
                                          color: 'black',
                                          fontWeight: 500,
                                        }}
                                      >
                                        <span>₹{reimber.amount}</span>{' '}
                                      </Typography>
                                      <Typography
                                        className="date pending"
                                        style={{ fontWeight: 500 }}
                                      >
                                        <span>
                                          {formatDate(reimber.created_at)}
                                        </span>{' '}
                                      </Typography>
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body
                                    isOpen={accordionState[reimber.id]}
                                  >
                                    <div className="listing-body">
                                      <div className="listing-desc">
                                        <h5 className="lis-title">Reason</h5>
                                        <h5 className="lis-content">
                                          {reimber.reason}
                                        </h5>
                                      </div>
                                      <div className="app-rejt mt-5 d-flex justify-content-between">
                                        <button
                                          onClick={() =>
                                            handleApproved(
                                              reimber.id,
                                              reimber.serialnumber
                                            )
                                          }
                                          className="btn btn-approve"
                                        >
                                          Approve
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleOpen(
                                              reimber.id,
                                              reimber.serialnumber
                                            )
                                          }
                                          className="btn btn-reject"
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    </div>
                                  </Accordion.Body>
                                </Accordion>
                              </div>
                            </div>
                          )
                      )
                    ) : (
                      <div className="d-flex justify-content-center align-items-center mt-4">
                        <Typography>No Data availabe</Typography>
                      </div>
                    )}
                  </Tab>

                  <Tab eventKey="History" title="History">
                    {historyData.length > 0 ? (
                      historyData.map((reimber, index) => (
                        <div key={index} className="row leave-item">
                          <div className="col-sm-12 row history-row position-relative">
                            {reimber.status == '2' ? (
                              <Accordion className="accord accord-2">
                                <Accordion.Header>
                                  <div className="leave-icon">
                                    <div className="leave-reject">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M15 9L9 15"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M9 9L15 15"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="leave-details">
                                    <Typography
                                      style={{
                                        fontSize: '18px',
                                        color: 'black',
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reimber.username}
                                    </Typography>
                                    <Typography
                                      className=""
                                      style={{
                                        color: 'black',
                                        fontWeight: 500,
                                      }}
                                    >
                                      <span>₹{reimber.amount}</span>{' '}
                                    </Typography>
                                    <Typography
                                      className="date pending"
                                      style={{ fontWeight: 500 }}
                                    >
                                      <span>
                                        {formatDate(reimber.created_at)}
                                      </span>{' '}
                                    </Typography>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="">
                                    <div className="listing-desc">
                                      <h5 className="lis-title">Reason</h5>
                                      <h5 className="lis-content">
                                        {reimber.reason}
                                      </h5>
                                    </div>
                                    <div className="listing-desc">
                                      <h5 className="lis-title">
                                        Reason of Rejection
                                      </h5>
                                      <h5 className="lis-content">
                                        {reimber.rejection_reason}
                                      </h5>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion>
                            ) : (
                              <Accordion className="accord accord-2">
                                <Accordion.Header>
                                  <div className="leave-icon">
                                    <div className="leave-approved">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                          stroke="white"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M22 4L12 14.01L9 11.01"
                                          stroke="white"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="leave-details">
                                    <Typography
                                      style={{
                                        fontSize: '18px',
                                        color: 'black',
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reimber.username}
                                    </Typography>
                                    <Typography
                                      className=""
                                      style={{
                                        color: 'black',
                                        fontWeight: 500,
                                      }}
                                    >
                                      <span>₹{reimber.amount}</span>{' '}
                                    </Typography>
                                    <Typography
                                      className="date pending"
                                      style={{ fontWeight: 500 }}
                                    >
                                      <span>
                                        {formatDate(reimber.created_at)}
                                      </span>{' '}
                                    </Typography>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body
                                  isOpen={accordionState[reimber.id]}
                                >
                                  <div className="listing-body">
                                    <div className="listing-desc">
                                      <h5 className="lis-title">Reason</h5>
                                      <h5 className="lis-content">
                                        {reimber.reason}
                                      </h5>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center align-items-center mt-4">
                        <Typography>No Data availabe</Typography>
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </div>
            )}
          </Container>
        </section>
      </section>

      {hrms_loggedin_user.role != 'admin' && (
        <a
          onClick={handleAddModalOpen}
          style={{
            width: 'fit-content',
            position: 'absolute',
            right: '20px',
            bottom: '50px',
          }}
          class="application-link"
        >
          <div class="leave-application-btn">
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 5V19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 12H19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </a>
      )}

      <Modal className="modalReject" show={open} onHide={handleClose}>
        <Modal.Body>
          <h2 style={{ textAlign: 'center' }}>Reason</h2>
          <Grid container>
            <Grid item xs={12}>
              <Form.Control
                as="textarea"
                placeholder="Enter rejection reason"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </Grid>
            <Grid
              xs={12}
              className="d-flex justify-content-center align-items-center mt-3"
            >
              <Button
                style={{
                  backgroundColor: '#FE3636',
                  border: '15px',
                  color: '#FFFFFF',
                }}
                type="submit"
                onClick={handleReject}
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal>

      <Modal show={addModalOpen} onHide={handleAddModalClose}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: '#dbe9f7' }}
        ></Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                value={newAmount}
                onChange={(e) => setNewAmount(e.target.value)}
                placeholder="Enter amount"
              />
            </Form.Group>
            <Form.Group controlId="formReason" className="mt-3">
              <Form.Label>Reason</Form.Label>
              <Form.Control
                type="text"
                value={newReason}
                onChange={(e) => setNewReason(e.target.value)}
                placeholder="Enter reason"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#dbe9f7' }}>
          <Button
            variant="contained"
            style={{
              width: '100%',
              color: '#fff',
              backgroundColor: 'rgb(33, 205, 18)',
            }}
            onClick={handleAddReimbursement}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Reimberssment
