import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/custom.css'
import '../../css/style.css'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import backendApi from '../../helper'
import Loader from '../../js/loader'
import { Typography } from '@mui/material'

const Performance = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [performances, setPerformances] = useState([])
  const userData = JSON.parse(localStorage.getItem('user_Data'))
  const queryParams = new URLSearchParams(location.search)
  var serialnumber = queryParams.get('serialnumber')
  serialnumber = serialnumber.replace(/ /g, '+')
  const month = queryParams.get('Month')
  const year = queryParams.get('year')

  const getCurrentDate = () => {
    const now = new Date()
    const year = now.getFullYear()
    let month = now.getMonth() + 1 // January is 0
    if (month < 10) {
      month = `0${month}` // Add leading zero for single-digit months
    }
    let day = now.getDate()
    if (day < 10) {
      day = `0${day}` // Add leading zero for single-digit days
    }
    return `${year}-${month}-${day}`
  }

  const [showModal, setShowModal] = useState(false)
  const [formData, setFormData] = useState({
    remark_date: '',
    category: '',
    remark: '',
  })
  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      Loader.open() // Show loader
      const data = {
        action: 'add_performance',
        serialnumber,
        ...formData, // Include form data in the request
      }

      const result = await backendApi(data, userData.user_auth) // Send POST request to backend

      Loader.close() // Hide loader

      if (!result.error) {
        console.log('Performance data added successfully:', result.message)
        fetchPerformances()
        setFormData({
          remark_date: '',
          category: '',
          remark: '',
        })
        // Optionally, you can update state or perform any other action here
      } else {
        console.log('Failed to add performance data:', result.message)
        // Handle error scenario, show error message, etc.
      }
    } catch (error) {
      Loader.close() // Hide loader on error
      console.log('Error during API call:', error)
      // Handle error, show error message, etc.
    }

    // After submission, close the modal
    handleClose()
  }

  const fetchPerformances = async () => {
    if (!serialnumber) {
      console.log('Serial number, month, or year not provided')
      return
    }

    Loader.open()
    const data = {
      action: 'fetch_performance',
      serialnumber,
      month,
      year,
    }

    try {
      const result = await backendApi(data, userData.user_auth)
      Loader.close()
      if (!result.error) {
        setPerformances(result.performance)
      } else {
        console.log('Failed to fetch month-wise data:', result.message)
      }
    } catch (error) {
      Loader.close()
      console.log('Error during API call:', error)
    }
  }

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetchPerformances()
  }, [])

  function formatDateFromAPI(apiDateString) {
    const dateObject = new Date(apiDateString)
    if (!(dateObject instanceof Date) || isNaN(dateObject)) {
      return 'Invalid Date'
    }
    const day = dateObject.getDate()
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
      dateObject
    )
    const year = dateObject.getFullYear()
    return `${day} ${month} ${year}`
  }

  return (
    <>
      <Container fluid className="hrms_content">
        <Row>
          <Col lg={12} className="p-0">
            {performances && performances.length > 0 ? (
              performances.map((performance, index) => (
                <Accordion defaultActiveKey="0" className="custom-accordion">
                  <Card>
                    <Accordion.Header>
                      <div className="col-sm-12 d-flex justify-content-between align-items-center">
                        <div
                          className={`${
                            performance.category == 1
                              ? 'dis-4 performance-icon me-3'
                              : performance.category == 2
                              ? 'dis-3 performance-icon me-3'
                              : performance.category == 3
                              ? 'dis-2 performance-icon me-3'
                              : 'performance-icon me-3'
                          }`}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16 2V6"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8 2V6"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3 10H21"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="performance-details">
                          <h5>{formatDateFromAPI(performance.remark_date)}</h5>
                          <p
                            className={
                              performance.category.toLowerCase() + ' m-0'
                            }
                          >
                            <span>
                              {performance.category === '1' ? (
                                <span style={{ color: 'green' }}>
                                  Appreciation
                                </span>
                              ) : performance.category === '2' ? (
                                <span style={{ color: 'red' }}>
                                  Disappointed
                                </span>
                              ) : performance.category === '3' ? (
                                <span style={{ color: 'orange' }}>
                                  Escalation
                                </span>
                              ) : (
                                '0'
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="perfor-desc">
                        <h5 className="per-title">Description</h5>
                        <h5 className="per-content">{performance.remark}</h5>
                      </div>
                    </Accordion.Body>
                  </Card>
                </Accordion>
              ))
            ) : (
              <Typography>No Data</Typography>
            )}
          </Col>
        </Row>
      </Container>
      {userData.role == 'admin' && (
        <a
          href="javascript:void(0)"
          style={{
            width: 'fit-content',
            position: 'absolute',
            right: '20px',
            bottom: '50px',
          }}
          className="application-link"
          onClick={handleShow}
        >
          <div className="leave-application-btn">
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 5V19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 12H19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </a>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: '#dbe9f7 ' }}>
          <Modal.Title>Add Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#f8f9fa' }}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="remark_date"
                value={formData.remark_date}
                onChange={handleChange}
                max={getCurrentDate()}
                required
              />
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="1">Appreciation</option>
                <option value="2">Disappointed</option>
                <option value="3">Escalation</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="remark"
                value={formData.remark}
                onChange={handleChange}
                rows={3}
                required
              />
            </Form.Group>
            <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  width: '100%',
                  marginTop: '10px',
                  padding: '5px 30px',
                  color: '#ffffff',
                  backgroundColor: '#21CD12',
                }}
              >
                Submit
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Performance
