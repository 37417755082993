import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'
import '../css/style.css'
import backendApi from '../helper'
// import { w3cwebsocket as W3CWebSocket } from 'websocket'

// const client = new W3CWebSocket('ws://localhost:8080/notifications')
const Navbar = ({ activeroute }) => {
  const [activeRoute, setActiveRoute] = useState('')
  const [notificationCount, setNotificationCount] = useState(0)
  const navigate = useNavigate()

  const [notifications, setNotifications] = useState([])

  // useEffect(() => {
  //   client.onopen = () => {
  //     console.log('WebSocket Client Connected')
  //   }

  //   client.onmessage = (message) => {
  //     const dataFromServer = JSON.parse(message.data)
  //     console.log('Received notification: ', dataFromServer)
  //     setNotifications((prevNotifications) => [
  //       ...prevNotifications,
  //       dataFromServer.notification,
  //     ])
  //     alert(dataFromServer.notification)
  //   }

  //   client.onclose = () => {
  //     console.log('WebSocket Client Disconnected')
  //   }

  //   client.onerror = (error) => {
  //     console.error('WebSocket Error: ', error)
  //   }
  // }, [])

  useEffect(() => {
    fetchNotifications()
    // Fetch user role when component mounts
    const userData = JSON.parse(localStorage.getItem('user_Data'))
  }, [])

  const fetchNotifications = async () => {
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'fetch_noti',
        serialnumber: hrms_user_id.serialnumber,
      }
      try {
        const result = await backendApi(data, hrms_user_id.user_auth)
        if (result.error === false) {
          const notifications = result.notification
          const unreadNotifications = notifications.filter(
            (notification) => notification.status == 0
          )
          const unreadCount = unreadNotifications.length
          setNotificationCount(unreadCount)
        } else {
          console.log('No notifications found')
        }
      } catch (error) {
        console.log('Error fetching notification data:', error)
      }
    }
  }

  useEffect(() => {
    if (activeroute === '/Admin-leave' || activeroute === '/leave') {
      setActiveRoute('Leaves')
    } else if (activeroute === '/Apply-leave') {
      setActiveRoute('Apply Leave')
    } else if (activeroute === '/profiles' || activeroute === '/history') {
      setActiveRoute('Monthly Report')
    } else if (
      activeroute === '/performanceReport' ||
      activeroute === '/history'
    ) {
      setActiveRoute('Monthly Report')
    } else if (activeroute === '/payslip') {
      setActiveRoute('Payslip')
    } else if (activeroute === '/reimberssment') {
      setActiveRoute('Reimbursement')
    } else if (activeroute === '/profiles2') {
      setActiveRoute('Profile')
    } else if (
      activeroute === '/monthreport' ||
      activeroute === '/attendance-page'
    ) {
      setActiveRoute('Attendance')
    } else if (
      activeroute === '/performance' ||
      activeroute === '/add-perfor'
    ) {
      setActiveRoute('Performance')
    } else if (activeroute === '/notifications') {
      setActiveRoute('Notifications')
    } else if (activeroute === '/listing-page') {
      setActiveRoute('Leave Applications')
    } else if (activeroute === '/requests') {
      setActiveRoute('Requests')
    } else if (activeroute === '/Dashboard' || activeroute === '/dashboard') {
      const name = JSON.parse(localStorage.getItem('user_Data'))
      setActiveRoute(`Hello, ${name?.username}`)
    }
    console.log(activeroute)
  }, [activeroute])

  const handleBackClick = (e) => {
    e.preventDefault()
    if (activeroute == '/attendance-page') {
      navigate('/Dashboard')
    } else {
      navigate(-1)
    }
  }

  const handleNotificationClick = (e) => {
    e.preventDefault()
    navigate('/notifications')
  }

  return (
    <>
      <div
        className="container mb-4 pt-5"
        style={{
          display:
            activeroute == '/login' || activeroute == '/Login'
              ? 'none'
              : 'block',
        }}
      >
        <div className="col-lg-12 d-flex justify-content-between">
          <div className="col-lg-6 attendance-heading leaves-heading align-items-center">
            {activeroute === '/Dashboard' || activeroute === '/dashboard' ? (
              ''
            ) : (
              <a href="#" className="backarrow" onClick={handleBackClick}>
                <svg
                  width="13"
                  height="20"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {/* Render notification count if available */}
              </a>
            )}
            <h3 className="head-title font-weight-bold mb-1">{activeRoute}</h3>
          </div>
          <div className="col-lg-6 log-out d-flex">
            <a
              href="#"
              className="notification"
              onClick={handleNotificationClick}
            >
              <svg
                className="feather feather-bell"
                fill="none"
                height="24"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                <path d="M13.73 21a2 2 0 0 1-3.46 0" />
              </svg>
              <div className="count">
                <span>{notificationCount}</span>
              </div>
            </a>
            <a
              href="#/login"
              className="logout-btn"
              onClick={() => localStorage.clear()}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 17L21 12L16 7"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 12H9"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
