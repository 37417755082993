import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/custom.css'
import '../../css/style.css'
import { Col, Container, Row } from 'react-bootstrap'
import staffimg from '../../img/a1702102000.png'
import { useNavigate } from 'react-router-dom'
import Loader from '../../js/loader'
import backendApi from '../../helper'
import maleStaff from '../../img/R.jpg'
import femaleStaff from '../../img/user-8.png'

const Profiles = () => {
  const [staffDetails, setStaffDetails] = useState([])
  const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
  const handleRequest = async () => {
    Loader.open()
    const data = {
      action: 'fetch_all_staff',
    }

    try {
      const result = await backendApi(data, hrms_user_id.user_auth)
      if (!result.error) {
        Loader.close()
        setStaffDetails(result.user_Details)
      } else {
        console.log('Failed to modify attendance:', result.message)
      }
    } catch (error) {
      console.log('Error during API call:', error)
    }
  }

  useEffect(() => {
    handleRequest()
  }, [])

  const navigate = useNavigate()
  return (
    <>
      <Container fluid className="hrms_content">
        <Row>
          <Col lg={12} className="p-0">
            <Col lg={12} className="p-0 mb-3">
              <div className="profile-title">
                <h4 className="m-0">Profiles</h4>
              </div>
            </Col>
            <Col lg={12} className="p-0">
              {staffDetails.length > 0 ? (
                staffDetails.map((profile, index) => (
                  <div
                    class="row leave-item"
                    key={index}
                    onClick={() =>
                      navigate(`/history?serialnumber=${profile.serialnumber}`)
                    }
                  >
                    <div class="col-sm-12 d-flex justify-content-between profile-row align-items-center">
                      <div className="profile-left">
                        <div class="profile-icon">
                          <img
                            src={
                              profile.gender == 'Male'
                                ? maleStaff
                                : profile.gender == 'Female'
                                ? femaleStaff
                                : ''
                            }
                            alt=""
                            height={'60px'}
                            width={'60px'}
                          />
                        </div>
                        <div class="Profile-details">
                          <h4>{profile.username}</h4>
                          <p class="profile-designation m-0">
                            {profile.designation}
                          </p>
                        </div>
                      </div>
                      <div class="profile-right">
                        <svg
                          class="feather feather-chevron-right"
                          fill="none"
                          height="30"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          width="30"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polyline points="9 18 15 12 9 6" />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h4>No profiles found</h4>
              )}
            </Col>
            {/* <div class="row leave-item">
              <div class="col-sm-12 d-flex justify-content-between profile-row align-items-center">
                <div className="profile-left">
                  <div class="profile-icon">
                    <img src={staffimg} alt="" height={'60px'} width={'60px'} />
                  </div>
                  <div class="Profile-details">
                    <h4>Jhon Doe</h4>
                    <p class="profile-designation m-0">Developer</p>
                  </div>
                </div>
                <div class="profile-right">
                  <svg
                    class="feather feather-chevron-right"
                    fill="none"
                    height="30"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="row leave-item">
              <div class="col-sm-12 d-flex justify-content-between profile-row align-items-center">
                <div className="profile-left">
                  <div class="profile-icon">
                    <img src={staffimg} alt="" height={'60px'} width={'60px'} />
                  </div>
                  <div class="Profile-details">
                    <h4>Jhon Doe</h4>
                    <p class="profile-designation m-0">Developer</p>
                  </div>
                </div>
                <div class="profile-right">
                  <svg
                    class="feather feather-chevron-right"
                    fill="none"
                    height="30"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="row leave-item">
              <div class="col-sm-12 d-flex justify-content-between profile-row align-items-center">
                <div className="profile-left">
                  <div class="profile-icon">
                    <img src={staffimg} alt="" height={'60px'} width={'60px'} />
                  </div>
                  <div class="Profile-details">
                    <h4>Jhon Doe</h4>
                    <p class="profile-designation m-0">Developer</p>
                  </div>
                </div>
                <div class="profile-right">
                  <svg
                    class="feather feather-chevron-right"
                    fill="none"
                    height="30"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="row leave-item">
              <div class="col-sm-12 d-flex justify-content-between profile-row align-items-center">
                <div className="profile-left">
                  <div class="profile-icon">
                    <img src={staffimg} alt="" height={'60px'} width={'60px'} />
                  </div>
                  <div class="Profile-details">
                    <h4>Jhon Doe</h4>
                    <p class="profile-designation m-0">Developer</p>
                  </div>
                </div>
                <div class="profile-right">
                  <svg
                    class="feather feather-chevron-right"
                    fill="none"
                    height="30"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline points="9 18 15 12 9 6" />
                  </svg>
                </div>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Profiles
