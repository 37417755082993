import React, { useState, useEffect } from 'react'
import { Container, Badge } from 'react-bootstrap'
import {
  IoArrowForward,
  IoBookOutline,
  IoCalendarClearOutline,
  IoDocumentTextOutline,
  IoVideocamOutline,
  IoPeopleOutline,
} from 'react-icons/io5'
import { HiOutlineDocumentPlus } from 'react-icons/hi2'
import { FiKey, FiUser } from 'react-icons/fi'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/style.css'
import { useNavigate } from 'react-router-dom'
import backendApi from '../../helper' // Import your backend API function

const Dash_Index = () => {
  const navigate = useNavigate()
  const userType = JSON.parse(localStorage.getItem('user_Data'))
  const [leaveCount, setLeaveCount] = useState(0) // State to store the
  const [reimCount, setReimCount] = useState(0) // State to store the counts
  const [timeCount, setTimeCount] = useState(0) // State to store the counts

  const fetchDashboardCounts = async () => {
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    try {
      const data = {
        action: 'dashboard_counts',
        serialnumber: hrms_user_id.serialnumber,
      }
      const result = await backendApi(data, hrms_user_id.user_auth)
      if (result.error === false) {
        setLeaveCount(result.leave)
        setReimCount(result.reimb)
        setTimeCount(result.time)
      } else {
        console.log('Failed to fetch dashboard counts:', result.message)
      }
    } catch (error) {
      console.log('Error fetching dashboard counts:', error)
    }
  }

  useEffect(() => {
    fetchDashboardCounts()

    console.log('Leave Count =====>', leaveCount)
    console.log('Time Count =====>', timeCount)
    console.log('Leave Count =====>', reimCount)
  }, [leaveCount, reimCount, timeCount])

  const AdmintabList = [
    {
      name: 'Attendance',
      icon: IoCalendarClearOutline,
      route: '/attendance-page',
    },
    {
      name: 'Leaves',
      icon: IoDocumentTextOutline,
      route: '/listing-page',
      count: leaveCount,
    },
    {
      name: 'Monthly Report',
      icon: IoCalendarClearOutline,
      route: '/profiles',
    },
    {
      name: 'Staff List',
      icon: IoPeopleOutline,
      route: '/profiles2',
    },
    {
      name: 'Reimbursement',
      icon: IoCalendarClearOutline,
      route: '/reimberssment',
      count: reimCount,
    },
    {
      name: 'Requests',
      icon: FiUser,
      route: '/requests',
      count: timeCount,
    },
  ]

  const UsertabList = [
    {
      name: 'Attendance',
      icon: IoCalendarClearOutline,
      route: '/attendance-page',
    },
    // { name: 'Task List', icon: IoBookOutline, route: '/' },
    { name: 'Leave', icon: IoDocumentTextOutline, route: '/leave' },
    // { name: 'Meetings', icon: IoVideocamOutline, route: '/' },
    {
      name: 'Monthly Report',
      icon: IoCalendarClearOutline,
      route: `/history?serialnumber=${userType.serialnumber}`,
    },
    {
      name: 'Reimbursement',
      icon: IoCalendarClearOutline,
      route: '/reimberssment',
      count: reimCount,
    },
    // { name: 'Credentials', icon: FiKey, route: '/' },
    // { name: 'Tools', icon: IoBuildOutline, route: '/' },
  ]

  return (
    <section className="Admin-dashboard">
      <Container fluid className="">
        {userType?.role === 'admin'
          ? AdmintabList.map((tab, index) => {
              const IconComponent = tab.icon
              return (
                <div
                  key={index}
                  className="single-item2 admintab history-row"
                  onClick={() => navigate(tab.route)}
                >
                  <div className="col-12 d-flex align-items-center">
                    <div className="icon-dis">
                      <IconComponent />
                    </div>
                    <div className="col-7 listing-details">
                      <h2
                        className="mb-0"
                        style={{ fontSize: '20px', fontWeight: 500 }}
                      >
                        {tab.name}
                      </h2>
                    </div>
                    <div
                      style={{ marginLeft: 'auto' }}
                      className="IoArrowForward"
                    >
                      <a className="notification">
                        <IoArrowForward />
                        {tab.count > 0 && (
                          <div className="count">
                            <span>{tab.count}</span>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              )
            })
          : UsertabList.map((tab, index) => {
              const IconComponent = tab.icon
              return (
                <div
                  key={index}
                  className="single-item2 admintab history-row"
                  onClick={() => navigate(tab.route)}
                >
                  <div className="col-12 d-flex align-items-center">
                    <div className="icon-dis">
                      <IconComponent />
                    </div>
                    <div className="col-7 listing-details">
                      <h2
                        className="mb-0"
                        style={{ fontSize: '20px', fontWeight: 500 }}
                      >
                        {tab.name}
                      </h2>
                    </div>
                    <div
                      style={{ marginLeft: 'auto' }}
                      className="IoArrowForward"
                    >
                      <IoArrowForward />
                      {/* {counts[tab.name] !== undefined && (
                      <Badge bg="info" className="ml-2">{counts[tab.name]}</Badge>
                    )} */}
                    </div>
                  </div>
                </div>
              )
            })}
      </Container>
    </section>
  )
}

export default Dash_Index
