import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Modal, Button, Form } from 'react-bootstrap'
import { Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import backendApi from '../helper'
import { format, isToday, isYesterday } from 'date-fns'
import Toastinette from '../js/toastinette'
import Loader from '../js/loader'
import { AUTOCLOSE, POSITION, PROGRESS } from '../constant'

const Inout_List = ({ loaddata, fetchUserLog }) => {
  const [d, setd] = useState(false)
  const [selectedLog, setSelectedLog] = useState(null)
  const [formData, setFormData] = useState({
    date: '',
    timeIn: '',
    timeOut: '',
    reason: '',
    gender: '',
  })

  const load_data = loaddata.user_bio_log
  const navigate = useNavigate()

  useEffect(() => {
    if (selectedLog) {
      setFormData({
        date: selectedLog.checkindate,
        timeIn: '',
        timeOut: '',
        reason: '',
        gender: '',
      })
    }
  }, [selectedLog])

  function formatDateFromAPI(apiDateString) {
    const dateObject = new Date(apiDateString)
    if (!(dateObject instanceof Date) || isNaN(dateObject)) {
      return 'Invalid Date'
    }
    const day = dateObject.getDate()
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
      dateObject
    )
    const year = dateObject.getFullYear()
    return `${day} ${month} ${year}`
  }

  const handleClose = () => {
    setd(false)
    setSelectedLog(null)
  }

  const handleShow = (log) => {
    setSelectedLog(log)
    setd(true)
  }

  function getdayfromdate(date) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const dayIndex = new Date(date).getDay()
    return days[dayIndex]
  }

  const formatTimeFromAPI = (time) => {
    if (!time) return ''
    const [hours, minutes] = time.split(':')
    const formattedTime = new Date()
    formattedTime.setHours(hours)
    formattedTime.setMinutes(minutes)
    return formattedTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleRequest = async () => {
    Loader.open()
    const userId = JSON.parse(localStorage.getItem('user_Data'))
    const data = {
      action: 'modify_attendance_time_by_id',
      employee_id: userId.serialnumber,
      date: formData.date,
      timein: formData.timeIn,
      timeout: formData.timeOut,
      reason: formData.reason,
      gender: formData.gender,
    }

    if (formData.timeOut <= formData.timeIn) {
      Toastinette.init({
        position: POSITION,
        title: 'Oops..!',
        message: 'Out Time must be greater than In Time',
        type: 'error',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      Loader.close()
      return
    }

    if (formData.reason.trim() === '') {
      Toastinette.init({
        position: POSITION,
        title: 'Oops..!',
        message: 'Reason cannot be empty',
        type: 'error',
        autoClose: AUTOCLOSE,
        progress: PROGRESS,
      })
      Loader.close()
      return
    }

    try {
      const result = await backendApi(data, userId.user_auth)
      if (!result.error) {
        Toastinette.init({
          position: POSITION,
          title: 'Congratulations',
          message: result.message,
          type: result.type,
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        Loader.close()

        handleClose()
        fetchUserLog()
      } else {
        Toastinette.init({
          position: POSITION,
          title: 'Oops...',
          message: result.message,
          type: result.type,
          autoClose: AUTOCLOSE,
          progress: PROGRESS,
        })
        Loader.close()
        fetchUserLog()
      }
    } catch (error) {
      Loader.close()
      console.log('Error during API call:', error)
    }
  }

  const isValidTime = (time) => time && time != '00:00:00'

  return (
    <React.Fragment>
      {load_data && load_data.length > 0 ? (
        load_data.map((log, index) => (
          <div key={index} className="p-0">
            <div className="row">
              <div
                className="col-12 row history-row  skeleton-mark-list"
                style={{ marginBottom: '5px' }}
              >
                <div className="d-flex align-items-center">
                  <div className="col-2 d-flex ml-3">
                    <div
                      className={`icon-dis ${
                        log.type == 0 || log.type == 1
                          ? log.timeout == '00:00:00'
                            ? log.type == 1
                              ? 'dis-1'
                              : 'dis-1'
                            : 'dis-1'
                          : log.type == 2
                          ? 'dis-2'
                          : 'dis-3'
                      }`}
                    >
                      <svg
                        width="36"
                        height="37"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16 2V6"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 2V6"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 10H21"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    className=" col-10 flex-column d-flex  "
                    style={{
                      marginLeft: '11px',
                      marginRight: '4px',
                      fontSize: '18px',
                    }}
                  >
                    <div
                      className="d-flex justify-content-between"
                      style={{ fontWeight: 'bold', fontSize: '20px' }}
                    >
                      <div className="justify-content-between">
                        <h5 className="his-date">
                          {formatDateFromAPI(log.checkindate)}{' '}
                          <span style={{ fontSize: '12px' }}>
                            {log.approval_status == 'approved'
                              ? '(Modified)'
                              : log.approval_status == 'rejected'
                              ? '(Rejected)'
                              : ''}
                          </span>
                        </h5>
                      </div>
                      <span
                        style={{
                          color:
                            getdayfromdate(log.checkindate) === 'Sun' ||
                            log.type === 3
                              ? '#FE3636'
                              : '#b3b0ed54',
                          float: 'right',
                        }}
                      >
                        {getdayfromdate(log.checkindate)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between his-time">
                      {log.type == 0 || log.type == 1 ? (
                        log.timeout === '00:00:00' ? (
                          log.type == 1 ? (
                            <div>
                              <span style={{ color: '#21CD12' }}>
                                {formatTimeFromAPI(log.timein)}
                              </span>{' '}
                              -{' '}
                              <span style={{ color: '#B3B0ED' }}>
                                Auto Dayend
                              </span>
                            </div>
                          ) : (
                            <div>
                              <span style={{ color: '#21CD12' }}>
                                {formatTimeFromAPI(log.timein)}
                              </span>{' '}
                              -{' '}
                              <span style={{ color: '#B3B0ED' }}>Pending</span>
                            </div>
                          )
                        ) : (
                          <div>
                            <span style={{ color: '#21CD12' }}>
                              {formatTimeFromAPI(log.timein)}
                            </span>{' '}
                            -{' '}
                            <span style={{ color: '#21CD12' }}>
                              {formatTimeFromAPI(log.timeout)}
                            </span>
                          </div>
                        )
                      ) : log.type == 2 ? (
                        <span style={{ color: '#F59F39' }}>Absent</span>
                      ) : (
                        <span style={{ color: '#FE3636' }}>Holiday</span>
                      )}
                      <div className="ml-4">
                        {log.approval_status != null ? (
                          log.approval_status == 'pending' ? (
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_386_1752)">
                                <path
                                  d="M9.99996 18.3333C14.6023 18.3333 18.3333 14.6023 18.3333 9.99996C18.3333 5.39759 14.6023 1.66663 9.99996 1.66663C5.39759 1.66663 1.66663 5.39759 1.66663 9.99996C1.66663 14.6023 5.39759 18.3333 9.99996 18.3333Z"
                                  stroke="#4934B6"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10 5V10L13.3333 11.6667"
                                  stroke="#4934B6"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_386_1752">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          ) : (
                            ''
                          )
                        ) : isYesterday(new Date(log.checkindate)) ? (
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handleShow(log)}
                          >
                            <g clip-path="url(#clip0_386_1326)">
                              <path
                                d="M9.16663 3.33337H3.33329C2.89127 3.33337 2.46734 3.50897 2.15478 3.82153C1.84222 4.13409 1.66663 4.55801 1.66663 5.00004V16.6667C1.66663 17.1087 1.84222 17.5327 2.15478 17.8452C2.46734 18.1578 2.89127 18.3334 3.33329 18.3334H15C15.442 18.3334 15.8659 18.1578 16.1785 17.8452C16.491 17.5327 16.6666 17.1087 16.6666 16.6667V10.8334"
                                stroke="#4934B6"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.4166 2.08332C15.7481 1.7518 16.1978 1.56555 16.6666 1.56555C17.1355 1.56555 17.5851 1.7518 17.9166 2.08332C18.2481 2.41484 18.4344 2.86448 18.4344 3.33332C18.4344 3.80216 18.2481 4.2518 17.9166 4.58332L9.99996 12.5L6.66663 13.3333L7.49996 9.99999L15.4166 2.08332Z"
                                stroke="#4934B6"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_386_1326">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : isToday(new Date(log.checkindate)) &&
                          isValidTime(log.timein) &&
                          isValidTime(log.timeout) ? (
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handleShow(log)}
                          >
                            <g clip-path="url(#clip0_386_1326)">
                              <path
                                d="M9.16663 3.33337H3.33329C2.89127 3.33337 2.46734 3.50897 2.15478 3.82153C1.84222 4.13409 1.66663 4.55801 1.66663 5.00004V16.6667C1.66663 17.1087 1.84222 17.5327 2.15478 17.8452C2.46734 18.1578 2.89127 18.3334 3.33329 18.3334H15C15.442 18.3334 15.8659 18.1578 16.1785 17.8452C16.491 17.5327 16.6666 17.1087 16.6666 16.6667V10.8334"
                                stroke="#4934B6"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.4166 2.08332C15.7481 1.7518 16.1978 1.56555 16.6666 1.56555C17.1355 1.56555 17.5851 1.7518 17.9166 2.08332C18.2481 2.41484 18.4344 2.86448 18.4344 3.33332C18.4344 3.80216 18.2481 4.2518 17.9166 4.58332L9.99996 12.5L6.66663 13.3333L7.49996 9.99999L15.4166 2.08332Z"
                                stroke="#4934B6"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_386_1326">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>no data</div>
      )}

      <Modal show={d} onHide={handleClose} className="justify-content-center">
        <Modal.Header closeButton style={{ backgroundColor: '#DBE9F7' }}>
          <Typography variant="h6">Edit</Typography>
        </Modal.Header>
        <Modal.Body
          style={{
            width: '100%',
            backgroundColor: '#DBE9F7',
            borderRadius: '10px',
            paddingBottom: '10px',
            paddingTop: '0px',
          }}
        >
          <Grid container>
            <Grid item xs={12} className="mt-3">
              <Grid container>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  <label style={{ fontSize: '13px', fontWeight: 600 }}>
                    Date:
                  </label>
                  <Typography style={{ fontSize: '13px' }}>
                    {selectedLog
                      ? formatDateFromAPI(selectedLog.checkindate)
                      : ''}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  <label style={{ fontSize: '13px', fontWeight: 600 }}>
                    In-Time:
                  </label>
                  <Typography style={{ fontSize: '13px' }}>
                    {selectedLog ? formatTimeFromAPI(selectedLog.timein) : ''}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  <label style={{ fontSize: '13px', fontWeight: 600 }}>
                    Out-Time:
                  </label>
                  <Typography style={{ fontSize: '13px' }}>
                    {selectedLog ? formatTimeFromAPI(selectedLog.timeout) : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '20px', fontWeight: 600 }}>
                In Time
              </label>
              <Form.Control
                type="time"
                name="timeIn"
                value={formData.timeIn}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '20px', fontWeight: 600 }}>
                Out Time
              </label>
              <Form.Control
                type="time"
                name="timeOut"
                value={formData.timeOut}
                onChange={handleChange}
                min={formData.timeIn}
              />
            </Grid>
            <Grid item xs={12} className="mt-3">
              <label style={{ fontSize: '20px', fontWeight: 600 }}>
                Reason
              </label>
              <Form.Control
                as="textarea"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              xs={12}
              className="d-flex justify-content-center align-items-center mt-3"
            >
              <Button
                style={{
                  width: '145px',
                  height: '45px',
                  borderRadius: '10px',
                  backgroundColor: '#21CD12',
                  border: '15px',
                  color: '#FFFFFF',
                }}
                onClick={handleRequest}
                type="submit"
              >
                Request
              </Button>
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default Inout_List
