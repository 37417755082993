import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'
import '../css/style.css'

const Admin_leave = () => {
  return (
    <>
      <section class="admin-leaves-page">
        <section class="leaves-list admin-list">
          <div class="container">
            <div class="col-lg-12 row">
              <div class="row leave-item">
                <a
                  class="p-0 text-decoration-none"
                  data-bs-toggle="collapse"
                  href="#collapseExample1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample1"
                  onclick="toggleClassAndRotate(event, 'collapseExample1')"
                >
                  <div class="col-sm-12 row history-row justify-content-between">
                    <div class="row">
                      <div class="leave-icon">
                        <div class="leave-approved">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22 4L12 14.01L9 11.01"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="leave-details">
                        <h4>Casual</h4>
                        <p class="date approved">
                          <span>12 Dec 2022</span> <span></span>
                        </p>
                      </div>
                    </div>
                    <div class="arrow-indicator d-flex align-items-center">
                      <svg
                        class="feather feather-chevron-down"
                        fill="none"
                        height="35"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        width="35"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </div>
                    <div class="duration">
                      <h6>1/2 days</h6>
                    </div>
                  </div>
                </a>
                <div
                  class="collapse p-0 history-row m-0 border-none collapse-data"
                  id="collapseExample1"
                >
                  <div class="card card-body">
                    Some placeholder content for the collapse component. This
                    panel is hidden by default but revealed when the user
                    activates the relevant trigger.
                  </div>
                </div>
              </div>
              <div class="row leave-item">
                <a
                  class="p-0 text-decoration-none"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  onclick="toggleClassAndRotate(event, 'collapseExample')"
                >
                  <div class="col-sm-12 row history-row justify-content-between">
                    <div class="row">
                      <div class="leave-icon">
                        <div class="leave-approved">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22 4L12 14.01L9 11.01"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="leave-details">
                        <h4>Sick</h4>
                        <p class="date approved">
                          <span>12 Dec 2022</span> <span></span>
                        </p>
                      </div>
                    </div>
                    <div class="arrow-indicator d-flex align-items-center">
                      <svg
                        class="feather feather-chevron-down"
                        fill="none"
                        height="35"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        width="35"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </div>
                    <div class="duration">
                      <h6>1/2 days</h6>
                    </div>
                  </div>
                </a>
                <div
                  class="collapse p-0 history-row m-0 border-none collapse-data"
                  id="collapseExample"
                >
                  <div class="card card-body">
                    Some placeholder content for the collapse component. This
                    panel is hidden by default but revealed when the user
                    activates the relevant trigger.
                  </div>
                </div>
              </div>
              <div class="row leave-item">
                {/* <!-- <div class="row"> --> */}
                <a
                  class="p-0 text-decoration-none"
                  data-bs-toggle="collapse"
                  href="#collapseExample2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample2"
                  onclick="toggleClassAndRotate(event, 'collapseExample2')"
                >
                  <div class="col-sm-12 row history-row justify-content-between">
                    <div class="row">
                      <div class="leave-icon">
                        <div class="leave-processing">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 6V12L16 14"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="leave-details">
                        <h4>Vacation</h4>
                        <p class="date processing">
                          <span>20 Nov 2022</span> - <span>25 Nov 2022</span>
                        </p>
                      </div>
                    </div>
                    <div class="arrow-indicator d-flex align-items-center">
                      <svg
                        class="feather feather-chevron-down"
                        fill="none"
                        height="35"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        width="35"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </div>
                    <div class="duration">
                      <h6>6 days</h6>
                    </div>
                  </div>
                </a>
                <div
                  class="collapse p-0 history-row m-0 border-none collapse-data"
                  id="collapseExample2"
                >
                  <div class="card card-body">
                    Some placeholder content for the collapse component. This
                    panel is hidden by default but revealed when the user
                    activates the relevant trigger.
                  </div>
                </div>
                {/* <!-- </div> --> */}
              </div>
              <div class="row leave-item">
                <div class="col-sm-12 row history-row ">
                  <div class="leave-icon">
                    <div class="leave-reject">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15 9L9 15"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 9L15 15"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="leave-details">
                    <h4>Exams</h4>
                    <p class="date rejected">
                      <span>28 Dec 2022</span> - <span>9 Jan 2023</span>
                    </p>
                  </div>
                  <div class="duration">
                    <h6>3 days</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <a
        href="#/Apply-leave"
        style={{
          width: 'fit-content',
          position: 'absolute',
          right: 0,
          bottom: '80px',
        }}
        class="application-link"
      >
        <div class="leave-application-btn">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 5V19"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 12H19"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </a>
    </>
  )
}

export default Admin_leave
