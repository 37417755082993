import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'
import '../css/style.css'
import backendApi from '../helper.js'
import Inout_List from '../attendence/ListAPI.js'
import Apply_leave from './Apply_leave.js'
import { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'
import '../css/style.css'
// import { Link } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Col,
  Container,
  Spinner,
  Tab,
  Tabs,
} from 'react-bootstrap'
import { Grid, Typography } from '@mui/material'
import Loader from '../js/loader.js'

const Leave = () => {
  const [leaveData, setLeaveData] = useState([])
  const [loading, setloading] = useState(true)

  const location = useLocation()

  useEffect(() => {
    fetchLeaveData()
  }, [])

  const fetchLeaveData = async () => {
    Loader.open()
    const hrms_user_id = JSON.parse(localStorage.getItem('user_Data'))
    if (hrms_user_id !== null) {
      const data = {
        action: 'view_all_leaves_by_id',
        id: hrms_user_id.serialnumber,
      }

      await backendApi(data, hrms_user_id.user_auth)
        .then((result) => {
          console.log('viewleave', result)
          if (
            result.error === false &&
            Array.isArray(result.leaves) &&
            result.leaves.length > 0
          ) {
            setloading(false)
            setLeaveData(result.leaves)
            Loader.close()
          } else {
            setloading(false)
            console.log('No leaves found')
            Loader.close()
          }
        })
        .catch(() => {
          console.log('error')
          setloading(false)
          Loader.close()
        })
    }
  }

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' }
    return new Date(dateString).toLocaleDateString('en-GB', options)
  }

  return (
    <React.Fragment>
      <section class="leaves-page hrms_content">
        <section class="leaves-list">
          <Container>
            <div className="col-lg-12 row">
              {leaveData.length > 0 ? (
                <>
                  {leaveData.map((leaveItem, index) => (
                    <div key={index} className="row leave-item p-0">
                      <div className="col-sm-12 row history-row position-relative">
                        <div className="days">
                          {leaveItem.leave_duration} Days
                        </div>
                        {leaveItem.status === '2' ? (
                          <Accordion className="accord accord-2">
                            <Accordion.Header>
                              <div className="leave-icon">
                                <div className="leave-reject">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15 9L9 15"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 9L15 15"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="leave-details">
                                <h4>
                                  {leaveItem.leave_type &&
                                  leaveItem.leave_type.length > 13
                                    ? leaveItem.leave_type.substring(0, 13) +
                                      '...'
                                    : leaveItem.leave_type}
                                </h4>
                                <p className="date rejected">
                                  <span>{formatDate(leaveItem.from_date)}</span>{' '}
                                  - <span>{formatDate(leaveItem.to_date)}</span>
                                </p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="ps-0 pe-0">
                              <div className="">
                                <div className="listing-timing d-flex mb-2">
                                  <div className="intime col-6">
                                    <h5 className="lis-title">From Date</h5>
                                    <h5 className="lis-content">
                                      {formatDate(leaveItem.from_date)}
                                    </h5>
                                  </div>
                                  <div className="outtime col-6">
                                    <h5 className="lis-title">To Date</h5>
                                    <h5 className="lis-content">
                                      {formatDate(leaveItem.to_date)}
                                    </h5>
                                  </div>
                                </div>
                                <div className="listing-desc">
                                  <h5 className="lis-title">Reason</h5>
                                  <h5 className="lis-content">
                                    {leaveItem.reason}
                                  </h5>
                                </div>
                                <div className="listing-desc">
                                  <h5 className="lis-title">
                                    Reason of Rejection
                                  </h5>
                                  <h5 className="lis-content">
                                    {leaveItem.reason_of_rejection}
                                  </h5>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion>
                        ) : leaveItem.status === '1' ? (
                          <Accordion className="accord accord-2">
                            <Accordion.Header>
                              <div className="leave-icon">
                                <div className="leave-approved">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M22 4L12 14.01L9 11.01"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="leave-details">
                                <h4>
                                  {leaveItem.leave_type &&
                                  leaveItem.leave_type.length > 13
                                    ? leaveItem.leave_type.substring(0, 13) +
                                      '...'
                                    : leaveItem.leave_type}
                                </h4>
                                <p className="date approved">
                                  <span>{formatDate(leaveItem.from_date)}</span>{' '}
                                  - <span>{formatDate(leaveItem.to_date)}</span>
                                </p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="ps-0 pe-0">
                              <div className="listing-body">
                                <div className="listing-timing d-flex mb-2">
                                  <div className="intime col-6">
                                    <h5 className="lis-title">From Date</h5>
                                    <h5 className="lis-content">
                                      {formatDate(leaveItem.from_date)}
                                    </h5>
                                  </div>
                                  <div className="outtime col-6">
                                    <h5 className="lis-title">To Date</h5>
                                    <h5 className="lis-content">
                                      {formatDate(leaveItem.to_date)}
                                    </h5>
                                  </div>
                                </div>
                                <div className="listing-desc">
                                  <h5 className="lis-title">Reason</h5>
                                  <h5 className="lis-content">
                                    {leaveItem.reason}
                                  </h5>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion>
                        ) : (
                          <Accordion className="accord accord-2">
                            <Accordion.Header>
                              <div className="leave-icon">
                                <div className="leave-processing">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12 6V12L16 14"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="leave-details">
                                <h4>
                                  {leaveItem.leave_type &&
                                  leaveItem.leave_type.length > 13
                                    ? leaveItem.leave_type.substring(0, 13) +
                                      '...'
                                    : leaveItem.leave_type}
                                </h4>
                                <p className="date pending">
                                  <span>{formatDate(leaveItem.from_date)}</span>{' '}
                                  - <span>{formatDate(leaveItem.to_date)}</span>
                                </p>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="ps-0 pe-0">
                              <div className="listing-body">
                                <div className="listing-timing d-flex mb-2">
                                  <div className="intime col-6">
                                    <h5 className="lis-title">From Date</h5>
                                    <h5 className="lis-content">
                                      {formatDate(leaveItem.from_date)}
                                    </h5>
                                  </div>
                                  <div className="outtime col-6">
                                    <h5 className="lis-title">To Date</h5>
                                    <h5 className="lis-content">
                                      {formatDate(leaveItem.to_date)}
                                    </h5>
                                  </div>
                                </div>
                                <div className="listing-desc">
                                  <h5 className="lis-title">Reason</h5>
                                  <h5 className="lis-content">
                                    {leaveItem.reason}
                                  </h5>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div
                  className="col- mt-5"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h4">No Leaves Found</Typography>
                </div>
              )}
            </div>
          </Container>
        </section>
      </section>

      <a
        href="#/Apply-leave"
        style={{
          width: 'fit-content',
          position: 'absolute',
          right: '20px',
          bottom: '50px',
          zIndex: 9999,
        }}
        class="application-link"
      >
        <div class="leave-application-btn">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 5V19"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 12H19"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </a>
    </React.Fragment>
  )
}

export default Leave
