import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'
import '../css/style.css'

function Footer() {
  return (
    <div className="hrms_footer d-none">
      <h6>
        <a href="https://mytecsys.in/">Mytecsys.in</a>
      </h6>
    </div>
  )
}

export default Footer
